import { Checkbox } from "@jobber/components/Checkbox";
import React, { useState } from "react";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";

export interface RequestReviewModalProps {
  open: boolean;
  onRequestClose(val: boolean): void;
  onSubmitReviewRequest(): void;
}

export function RequestReviewModal({
  open,
  onSubmitReviewRequest,
  onRequestClose,
}: RequestReviewModalProps) {
  const url = "https://forms.gle/etnZAQN8F6X62GyMA";
  const [enableSubmit, setEnableSubmit] = useState(false);

  function submitReviewRequest() {
    setEnableSubmit(false);
    onRequestClose(false);
    onSubmitReviewRequest();
  }

  return (
    <Modal
      title="Ready for a review?"
      open={open}
      onRequestClose={() => {
        setEnableSubmit(false);
        onRequestClose(false);
      }}
      primaryAction={{
        label: "Request Review",
        onClick: submitReviewRequest,
        disabled: !enableSubmit,
      }}
    >
      <Content>
        <Text>
          Before we begin the app review process, you must complete our{" "}
          <a href={url} target={"_blank"} rel="noreferrer">
            Pre-Submission Checklist
          </a>
        </Text>
        <Checkbox
          label="I have completed the Pre-Submission Checklist"
          onChange={setEnableSubmit}
        />
      </Content>
    </Modal>
  );
}
