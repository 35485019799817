import React from "react";
import { Heading } from "@jobber/components/Heading";
import { Content } from "@jobber/components/Content";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import { navigate } from "gatsby";
import { Banner } from "@/components/Banner";
import { ApplicationList } from "@/features/Application/ApplicationList";
import { useAmplitude } from "@/hooks/useAmplitude";
import * as styles from "./ManageApps.module.css";

export function ManageApps() {
  const { logEvent } = useAmplitude();
  return (
    <>
      <div className={styles.manageAppsWrapper}>
        <Banner className={styles.banner} />
        <Page
          title="Manage Apps"
          intro="Apps are software that can connect to Jobber accounts and data. They
    allow you to build new features and functionality on top of Jobber."
          width="fill"
          primaryAction={{
            icon: "add",
            label: "New",
            onClick: () => {
              navigate("/apps/new");
              logEvent({ name: "Clicked to Start Creating App" });
            },
          }}
        >
          <Content>
            <div className={styles.twoThirdsGrid}>
              <ApplicationList />
              <Content spacing="large">
                <Content spacing="small">
                  <Heading level={3}>Need Help</Heading>
                  <Text>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:api-support@getjobber.com"
                    >
                      Submit a request
                    </a>{" "}
                    to our Developer Center & API support team
                  </Text>
                </Content>
                <Content spacing="small">
                  <Heading level={3}>Feedback</Heading>
                  <Text>
                    Shape the developer experience by{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://jobber.typeform.com/to/i8U8PKD4"
                    >
                      providing us feedback
                    </a>
                  </Text>
                </Content>
              </Content>
            </div>
          </Content>
        </Page>
      </div>
    </>
  );
}
