import React, { useRef } from "react";
import { Form, FormRef } from "@jobber/components/Form";
import { Content } from "@jobber/components/Content";
import { Card } from "@jobber/components/Card";
import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import { Page } from "@jobber/components/Page";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { Link, navigate } from "gatsby";
import { Divider } from "@jobber/components/Divider";
import { FileUpload } from "@jobber/components/InputFile";
import { Icon } from "@jobber/components/dist/Icon";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import {
  ApplicationStateTypeEnum,
  DirectUploadCompleteMutation,
  DirectUploadCreateInput,
  DirectUploadCreateMutation,
} from "@/utils/graphql/types";
import {
  isAppEditable,
  isAppInReview,
  isAppRevision,
} from "@/utils/applicationStatus";
import { Banner } from "@/components/Banner";
import { ScopesForm } from "./ScopesForm";
import * as styles from "./ApplicationForm.module.css";
import { Feature, FeatureList } from "./FeatureList";
import { WebHook, WebHookList } from "./WebHookList";
import { Action } from "./ApplicationFormReducer";
import { ImageGallery } from "./ImageGallery";
import { LogoUpload } from "./LogoUpload";
import { ScopeCollection } from "./ScopesForm/initialState";
import { ApplicationStatus } from "../ApplicationStatus";

export interface ApplicationFormProps {
  id?: string;
  title: string;
  description: string;
  author: string;
  callback?: string;
  manageAppUrl?: string;
  scopes: ScopeCollection;
  reauthorizeMessage?: string;
  status: ApplicationStateTypeEnum;
  clientId?: string;
  secret?: string;
  features: Feature[];
  webHooks: WebHook[];
  refreshTokenRotation: boolean;
  isLoadingData?: boolean;
  isLoadingSave?: boolean;
  isDirty?: boolean;
  isScopesDirty?: boolean;
  files: GalleryFile[];
  logoFile: FileUpload | undefined;
  originalScopes: ScopeCollection;
  handleLogoUpload(file: FileUpload): void;
  handleUpload(file: FileUpload): void;
  handleDelete(file: GalleryFile): void;
  onSave(): void;
  dispatch(action: Action): void;
  createDirectUpload(
    input: DirectUploadCreateInput,
  ): Promise<DirectUploadCreateMutation | undefined>;
  completeDirectUpload(
    key: string,
  ): Promise<DirectUploadCompleteMutation | undefined>;
  handleLogoDelete(): void;
  termsOfServiceUrl?: string;
  privacyPolicyUrl?: string;
}

export interface GalleryFile extends Omit<FileUpload, "src"> {
  src: string;
}

export function ApplicationForm({
  id,
  title,
  description,
  author,
  callback,
  manageAppUrl,
  webHooks,
  refreshTokenRotation,
  scopes,
  reauthorizeMessage,
  status,
  originalScopes,
  clientId,
  secret,
  isLoadingData = true,
  isLoadingSave = false,
  features,
  files,
  logoFile,
  handleLogoUpload,
  handleUpload,
  handleDelete,
  createDirectUpload,
  onSave,
  dispatch,
  handleLogoDelete,
  completeDirectUpload,
  termsOfServiceUrl,
  privacyPolicyUrl,
}: ApplicationFormProps) {
  const formRef = useRef<FormRef>(null);
  const formEditable = isAppEditable(status);
  const isTemporaryOAuthCreds = isAppRevision(status);

  return (
    <div className={styles.applicationForm}>
      <Page title="">
        <Banner />
        <Text variation="success">
          <a className={styles.manageAppsButton} href="/apps">
            <Icon name="arrowLeft" color="green" /> Manage Apps
          </a>
        </Text>
        <div className={styles.appHeading}>
          <Heading level={1}>{id === undefined ? "New App" : title}</Heading>
          <ApplicationStatus status={status} />
        </div>
        <Content spacing="large">
          <Text>{formSubtitle()}</Text>
          <Form ref={formRef} onSubmit={onSave}>
            <div className={styles.form}>
              <Content>
                <Card title="Details">
                  <Content>
                    <InputText
                      placeholder="App name"
                      readonly={!formEditable}
                      value={title}
                      validations={{
                        required: {
                          value: true,
                          message: "Enter your app name",
                        },
                        minLength: {
                          value: 4,
                          message:
                            "Please enter a name at least 4 characters in length",
                        },
                      }}
                      onChange={newValue =>
                        dispatch({
                          type: "Change Title",
                          value: newValue.toString(),
                        })
                      }
                      loading={isLoadingData}
                    />
                    <InputText
                      placeholder="Developer name"
                      readonly={!formEditable}
                      value={author}
                      validations={{
                        required: {
                          value: true,
                          message: "Enter a developer name",
                        },
                        minLength: {
                          value: 4,
                          message:
                            "Please enter a name at least 4 characters in length",
                        },
                      }}
                      onChange={newValue =>
                        dispatch({
                          type: "Change Author",
                          value: newValue.toString(),
                        })
                      }
                      loading={isLoadingData}
                    />
                    <Content spacing="small">
                      <InputText
                        placeholder="Callback URL"
                        readonly={!formEditable}
                        value={callback}
                        onChange={newValue =>
                          dispatch({
                            type: "Change Callback",
                            value: newValue.toString(),
                          })
                        }
                        loading={isLoadingData}
                      />
                      <Text variation="subdued">
                        The URL that is invoked after the authentication process
                      </Text>
                    </Content>

                    <Content spacing="small">
                      <InputText
                        placeholder="Manage App URL (Optional)"
                        readonly={!formEditable}
                        value={manageAppUrl}
                        onChange={newValue =>
                          dispatch({
                            type: "Change Manage App Url",
                            value: newValue.toString(),
                          })
                        }
                        loading={isLoadingData}
                      />
                      <Text variation="subdued">
                        The URL that is invoked after clicking the &ldquo;Manage
                        App&rdquo; button from Jobber&lsquo;s App Marketplace
                      </Text>
                    </Content>
                    <Heading level={3}>App description</Heading>
                    <Content spacing="small">
                      <InputText
                        placeholder="Short description"
                        readonly={!formEditable}
                        multiline
                        value={description}
                        maxLength={200}
                        validations={{
                          required: {
                            value: true,
                            message: "Enter a short description",
                          },
                        }}
                        onChange={newValue =>
                          dispatch({
                            type: "Change Description",
                            value: newValue.toString(),
                          })
                        }
                        loading={isLoadingData}
                      />
                      <Text variation="subdued">Max 350 characters</Text>
                    </Content>
                    <FeatureList
                      features={features}
                      readonly={!formEditable}
                      onChange={newValue =>
                        dispatch({ type: "Change Features", value: newValue })
                      }
                    />
                    <Heading level={3}>App logo</Heading>
                    <LogoUpload
                      logoFile={logoFile}
                      readonly={!formEditable}
                      dispatch={dispatch}
                      handleUpload={handleLogoUpload}
                      createDirectUpload={createDirectUpload}
                      handleDelete={handleLogoDelete}
                      completeDirectUpload={completeDirectUpload}
                    />
                    <Heading level={3}>Gallery</Heading>
                    <ImageGallery
                      files={files}
                      readonly={!formEditable}
                      dispatch={dispatch}
                      handleUpload={handleUpload}
                      handleDelete={handleDelete}
                      createDirectUpload={createDirectUpload}
                    />
                    <Heading level={3}>Security information</Heading>
                    <Text variation="subdued">
                      Links to your app&apos;s Terms of Service and Privacy
                      Policy are needed in order to submit your app to be listed
                      in Jobber&apos;s App Marketplace. These links will be
                      provided to users when authorizing the connection to your
                      app.
                    </Text>
                    <InputText
                      placeholder="Terms of Service URL"
                      readonly={!formEditable}
                      value={termsOfServiceUrl}
                      onChange={newValue =>
                        dispatch({
                          type: "Change Terms of Service Url",
                          value: newValue.toString(),
                        })
                      }
                      loading={isLoadingData}
                    />
                    <InputText
                      placeholder="Privacy Policy URL"
                      readonly={!formEditable}
                      value={privacyPolicyUrl}
                      onChange={newValue =>
                        dispatch({
                          type: "Change Privacy Policy Url",
                          value: newValue.toString(),
                        })
                      }
                      loading={isLoadingData}
                    />
                  </Content>
                </Card>
                <Card title="Scopes">
                  <Content>
                    <ScopesForm
                      readonly={!formEditable}
                      scopes={scopes}
                      originalScopes={originalScopes}
                      applicationState={status}
                      reauthorizeMessageValue={reauthorizeMessage}
                      onScopesChange={newValue =>
                        dispatch({
                          type: "Change Scope",
                          value: newValue,
                        })
                      }
                      onReauthorizationMessageChange={newValue =>
                        dispatch({
                          type: "Change Reauthorize Message",
                          value: newValue,
                        })
                      }
                    />
                  </Content>
                </Card>
                <Card title="Webhooks">
                  <Content>
                    <Content>
                      <Text>
                        Get notified when these events are triggered. Read our{" "}
                        <Link to="/docs/using_jobbers_api/setting_up_webhooks/">
                          webhook documentation
                        </Link>{" "}
                        for more information and to view payload examples.
                      </Text>
                      <Divider />
                    </Content>
                    <WebHookList
                      webHooks={webHooks}
                      readonly={!formEditable}
                      onChange={newWebHooks => {
                        dispatch({
                          type: "Change Web Hooks",
                          value: newWebHooks,
                        });
                      }}
                      onDelete={removedWebHook => {
                        dispatch({
                          type: "Handle Removed Web Hook",
                          value: removedWebHook,
                        });
                      }}
                    />
                  </Content>
                </Card>
                <Card title="Refresh token rotation">
                  <Content>
                    <FeatureSwitch
                      enabled={refreshTokenRotation}
                      disabled={!formEditable}
                      title="Refresh token rotation"
                      description="Read our [refresh token rotation documentation](/docs/building_your_app/refresh_token_rotation) for more information"
                      onSwitch={refreshTokenRotationSwitch => {
                        dispatch({
                          type: "Handle Refresh Token Rotation",
                          value: refreshTokenRotationSwitch,
                        });
                      }}
                    ></FeatureSwitch>
                  </Content>
                </Card>
                {id != undefined && (
                  <Card
                    title={"Authorization" + (isTemporaryOAuthCreds ? "*" : "")}
                  >
                    <Content>
                      <InputText
                        placeholder="Client ID"
                        readonly
                        value={clientId}
                        loading={isLoadingData}
                      />
                      <InputText
                        placeholder="Client secret"
                        readonly
                        value={secret}
                        loading={isLoadingData}
                      />
                      {isTemporaryOAuthCreds && (
                        <Text>
                          * The client ID and secret are temporary for this
                          revision. The client ID and secret for the published
                          app will not change when the revision is approved.
                        </Text>
                      )}
                    </Content>
                  </Card>
                )}
                {formEditable && (
                  <div className={styles.buttonGroup}>
                    <Button
                      label="Cancel"
                      variation="destructive"
                      onClick={
                        /* istanbul ignore next */ () => navigate("/apps")
                      }
                    />
                    <Button label="Save App" submit loading={isLoadingSave} />
                  </div>
                )}
              </Content>
            </div>
          </Form>
        </Content>
      </Page>
    </div>
  );

  function formSubtitle() {
    if (id === undefined) {
      return "Fill in the details and scopes below to create your new app";
    } else if (formEditable) {
      return "Update details and scopes below to revise your app draft";
    } else if (isAppInReview(status)) {
      return "App in review cannot be edited. Cancel review to edit fields";
    } else if (status === ApplicationStateTypeEnum.APPROVED) {
      return "Approved apps can not be edited. To edit this app, go back to manage apps and create a revision draft.";
    } else {
      return 'The details of your existing app. To change these details, visit "Manage Apps" and "Create a revision" for the existing app';
    }
  }
}
