import React, { useCallback } from "react";
import { InlineLabel, InlineLabelColors } from "@jobber/components/InlineLabel";
import { ApplicationStateTypeEnum } from "@/utils/graphql/types";

interface ApplicationStatusProps {
  status: ApplicationStateTypeEnum;
  size?: "base" | "large" | "larger";
}

export function ApplicationStatus({
  status,
  size = "large",
}: ApplicationStatusProps) {
  const text = useCallback(() => status.replace(/_/g, " "), [status])();
  const colour = useCallback((): InlineLabelColors => {
    switch (status) {
      case ApplicationStateTypeEnum.REVISION_DRAFT:
      case ApplicationStateTypeEnum.DRAFT:
      default: {
        return "greyBlue";
      }
      case ApplicationStateTypeEnum.APPROVED:
      case ApplicationStateTypeEnum.PUBLISHED: {
        return "green";
      }
      case ApplicationStateTypeEnum.IN_REVIEW:
      case ApplicationStateTypeEnum.REVISION_IN_REVIEW: {
        return "orange";
      }
      case ApplicationStateTypeEnum.CHANGES_REQUESTED:
      case ApplicationStateTypeEnum.REVISION_CHANGES_REQUESTED: {
        return "red";
      }
      case ApplicationStateTypeEnum.PUBLISHED_BETA: {
        return "lightBlue";
      }
    }
  }, [status])();

  return (
    <InlineLabel size={size} color={colour}>
      {text}
    </InlineLabel>
  );
}
