/* eslint-disable max-statements */
import { useReducer } from "react";
import { cloneDeep } from "lodash";
import { ApplicationStateTypeEnum, FileStorage } from "@/utils/graphql/types";
import { Feature } from "@/features/Application/ApplicationForm/FeatureList";
import { WebHook } from "@/features/Application/ApplicationForm/WebHookList";
import { ScopeCollection } from "../ScopesForm";
import { initialState } from "../ScopesForm/initialState";

export interface ApplicationState {
  id?: string;
  title: string;
  description: string;
  author: string;
  status: ApplicationStateTypeEnum;
  callback?: string;
  manageAppUrl?: string;
  scopes: ScopeCollection;
  reauthorizeMessage?: string;
  features: Feature[];
  webHooks: WebHook[];
  refreshTokenRotation: boolean;
  clientId?: string;
  secret?: string;
  removedWebHooks: WebHook[];
  imagesToAdd: string[];
  imagesToRemove: string[];
  galleryImages: FileStorage[];
  logo: FileStorage | undefined;
  logoId: string | undefined;
  termsOfServiceUrl?: string;
  privacyPolicyUrl?: string;
}

export interface ReducerState extends ApplicationState {
  isDirty: boolean;
}

export type Action =
  | { type: "Change Title"; value: string }
  | { type: "Change Description"; value: string }
  | { type: "Change Author"; value: string }
  | { type: "Change Callback"; value: string }
  | { type: "Change Manage App Url"; value: string }
  | { type: "Change Scope"; value: ScopeCollection }
  | { type: "Change Reauthorize Message"; value: string }
  | { type: "Change Features"; value: Feature[] }
  | { type: "Change Web Hooks"; value: WebHook[] }
  | { type: "Handle Refresh Token Rotation"; value: boolean }
  | { type: "Handle Removed Web Hook"; value: WebHook }
  | { type: "Add image"; value: string }
  | { type: "Remove image"; value: string }
  | { type: "Add logo"; value: string }
  | { type: "Remove logo" }
  | { type: "Set State"; value: ApplicationState }
  | { type: "Change Terms of Service Url"; value: string }
  | { type: "Change Privacy Policy Url"; value: string };

export function ApplicationFormStateReducer(
  state: ReducerState,
  action: Action,
): ReducerState {
  switch (action.type) {
    case "Change Title": {
      return { ...state, title: action.value, isDirty: true };
    }
    case "Change Description": {
      return { ...state, description: action.value, isDirty: true };
    }
    case "Change Author": {
      return { ...state, author: action.value, isDirty: true };
    }
    case "Change Callback": {
      return { ...state, callback: action.value, isDirty: true };
    }
    case "Change Manage App Url": {
      return { ...state, manageAppUrl: action.value, isDirty: true };
    }
    case "Change Scope": {
      return {
        ...state,
        scopes: action.value,
        isDirty: true,
      };
    }
    case "Change Reauthorize Message": {
      return {
        ...state,
        reauthorizeMessage: action.value,
        isDirty: true,
      };
    }
    case "Change Features": {
      return { ...state, features: action.value, isDirty: true };
    }
    case "Change Web Hooks": {
      return { ...state, webHooks: action.value, isDirty: true };
    }
    case "Handle Refresh Token Rotation": {
      return { ...state, refreshTokenRotation: action.value, isDirty: true };
    }
    case "Add image": {
      return {
        ...state,
        imagesToAdd: [...state.imagesToAdd, action.value],
        isDirty: true,
      };
    }
    case "Remove image": {
      const updatedImagesToAdd = state.imagesToAdd.filter(element => {
        return element !== action.value;
      });

      const imageAttachedToApp =
        updatedImagesToAdd.length === state.imagesToAdd.length;

      let updatedImagesToRemove = state.imagesToRemove;
      if (imageAttachedToApp) {
        updatedImagesToRemove = [...state.imagesToRemove, action.value];
      }

      return {
        ...state,
        imagesToRemove: updatedImagesToRemove,
        imagesToAdd: updatedImagesToAdd,
        galleryImages: state.galleryImages.filter(element => {
          return element.id !== action.value;
        }),
        isDirty: true,
      };
    }

    case "Handle Removed Web Hook": {
      return {
        ...state,
        removedWebHooks: [...state.removedWebHooks, action.value],
        isDirty: true,
      };
    }
    case "Add logo": {
      return {
        ...state,
        logoId: action.value,
        isDirty: true,
      };
    }
    case "Remove logo": {
      return {
        ...state,
        logo: state.logo ? { ...state.logo, id: "" } : undefined,
        logoId: "",
        isDirty: true,
      };
    }
    case "Set State": {
      return {
        ...state,
        ...action.value,
        isDirty: false,
      };
    }
    case "Change Terms of Service Url": {
      return {
        ...state,
        termsOfServiceUrl: action.value,
        isDirty: true,
      };
    }
    case "Change Privacy Policy Url": {
      return {
        ...state,
        privacyPolicyUrl: action.value,
        isDirty: true,
      };
    }
    default: {
      return state;
    }
  }
}

export function useApplicationFormReducer() {
  const [state, dispatch] = useReducer(ApplicationFormStateReducer, {
    title: "",
    description: "",
    author: "",
    callback: "",
    manageAppUrl: "",
    scopes: cloneDeep(initialState),
    removedWebHooks: [],
    features: [{ label: "" }],
    webHooks: [] as WebHook[],
    refreshTokenRotation: true,
    status: ApplicationStateTypeEnum.DRAFT,
    isDirty: false,
    imagesToAdd: [] as string[],
    imagesToRemove: [] as string[],
    galleryImages: [] as FileStorage[],
    logo: { id: "" } as FileStorage,
    logoId: "",
    termsOfServiceUrl: "",
    privacyPolicyUrl: "",
  });

  return { state, dispatch };
}
