import React, { ReactFragment } from "react";
import { FeatureSwitch } from "@jobber/components/FeatureSwitch";
import { Checkbox } from "@jobber/components/Checkbox";
import { Text } from "@jobber/components/Text";
import { Tooltip } from "@jobber/components/Tooltip";
import { Icon } from "@jobber/components/dist/Icon";
import * as styles from "./ScopeItem.module.css";

export interface ScopeItemType {
  label: string;
  key: string;
  description?: ReactFragment;
  value: boolean;
  permissionTypes: Record<string, boolean>;
  requiredScopes: string[]; // other scopes that this scope requires
  allowedAsOptional: boolean; // can scope be shown as optional
  optional: boolean; // optional scope's value for the integration, if available
  additionalInformation?: string;
}

interface ScopesItemProps {
  item: ScopeItemType;
  readonly?: boolean;
  onChange(scopeItem: ScopeItemType): void;
}

export function ScopeItem({ item, readonly, onChange }: ScopesItemProps) {
  const isCheckboxDisabled = (
    label: string,
    permissionTypes: Record<string, boolean>,
  ) => {
    const isFirstPermission = label === Object.keys(permissionTypes)[0];
    return readonly || isFirstPermission;
  };

  const handleScopeItemChange = (
    value: boolean,
    permissions: Record<string, boolean>,
  ) => {
    onChange({ ...item, value: value, permissionTypes: permissions });
  };

  const setFirstPermissionTrue = (permissionType: Record<string, boolean>) => {
    // first permission is default ON when the switch is on
    return { [Object.keys(permissionType)[0]]: true };
  };

  const handleSwitchChange = (switchValue: boolean) => {
    handleScopeItemChange(
      switchValue,
      switchValue
        ? {
            // map to check box values
            ...item.permissionTypes,
            ...(switchValue && setFirstPermissionTrue(item.permissionTypes)),
          }
        : // turn off all permissions
          Object.keys(item.permissionTypes).reduce(
            (prev, curr) => ({ ...prev, [curr]: false }),
            { ...item.permissionTypes },
          ),
    );
  };

  const handleCheckChange = (value: boolean, label: string) => {
    handleScopeItemChange(item.value, {
      ...item.permissionTypes,
      [label]: value,
    });
  };

  const handleOptionalChange = (value: boolean) => {
    onChange({ ...item, optional: value });
  };

  return (
    <>
      <FeatureSwitch
        enabled={item.value}
        disabled={readonly}
        title={item.label}
        onSwitch={handleSwitchChange}
      >
        <div className={styles.scope_permission_types}>
          {!item.value && !readonly && (
            <Text variation="subdued">{item.description}</Text>
          )}
          {item.value && item.additionalInformation && (
            <Text variation="subdued">{item.additionalInformation}</Text>
          )}
          {item.value &&
            item.permissionTypes &&
            Object.keys(item.permissionTypes).map(permissionKey => {
              const value = item.permissionTypes[permissionKey];
              return (
                <Checkbox
                  key={permissionKey}
                  label={permissionKey}
                  checked={value}
                  value={String(value)}
                  onChange={newValue => {
                    handleCheckChange(newValue, permissionKey);
                  }}
                  disabled={isCheckboxDisabled(
                    permissionKey,
                    item.permissionTypes,
                  )}
                />
              );
            })}
        </div>
      </FeatureSwitch>
      {item.value && item.allowedAsOptional && (
        <div className={styles.optional_scope}>
          <Checkbox
            key={"optional"}
            checked={item.optional}
            value={String(item.optional)}
            onChange={newValue => {
              handleOptionalChange(newValue);
            }}
            disabled={readonly}
          >
            <Text>
              Optional{" "}
              <Tooltip
                message="If checked, plans that don't have access to this scope will still
              be able to connect your app"
              >
                <Icon name="help" />
              </Tooltip>
            </Text>
          </Checkbox>
        </div>
      )}
    </>
  );
}
