import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import {
  FileUpload,
  InputFile,
  UploadParams,
} from "@jobber/components/InputFile";
import { Gallery } from "@jobber/components/Gallery";
import {
  DirectUploadCreateInput,
  DirectUploadCreateMutation,
} from "@/utils/graphql/types";
import { Action } from "@/features/Application/ApplicationForm/ApplicationFormReducer";
import { FileChecksum } from "@/utils/fileChecksum";
import * as styles from "../ApplicationForm.module.css";

export interface GalleryFile extends Omit<FileUpload, "src"> {
  src: string;
}

export interface ImageGalleryProps {
  files: GalleryFile[];
  readonly: boolean;
  dispatch(action: Action): void;
  handleUpload(file: FileUpload): void;
  handleDelete(file: GalleryFile): void;
  createDirectUpload(
    input: DirectUploadCreateInput,
  ): Promise<DirectUploadCreateMutation | undefined>;
}

export const BAD_IMAGE = "BAD_IMAGE";
export function ImageGallery({
  files,
  readonly,
  dispatch,
  handleUpload,
  handleDelete,
  createDirectUpload,
}: ImageGalleryProps) {
  function calculateChecksum(file: File) {
    return new Promise<string>((resolve, reject) => {
      FileChecksum.create(file, (error, checksum) => {
        /* istanbul ignore next */
        if (error) {
          reject(error);
          return;
        }

        resolve(checksum);
      });
    });
  }

  async function getUploadParams(file: File): Promise<UploadParams> {
    const checksum = await calculateChecksum(file);

    const result = await createDirectUpload({
      checksum: checksum,
      filename: file.name,
      contentType: file.type,
      fileSize: file.size,
    });

    if (result === undefined) {
      return {
        url: "",
        key: BAD_IMAGE,
      };
    }

    const directUpload = result.directUploadCreate.directUpload;
    /* istanbul ignore next */
    const url = directUpload?.url || "";
    const fields = directUpload?.parameters.reduce(
      (previousFields, currentField) => {
        return { ...previousFields, [currentField.name]: currentField.value };
      },
      {},
    );

    return {
      url: url,
      fields: fields,
      key: result.directUploadCreate.directUpload?.id,
      httpMethod: "PUT",
    };
  }

  async function handleUploadComplete(file: FileUpload) {
    await handleUpload(file);
    dispatch({
      type: "Add image",
      value: file.key,
    });
  }

  return (
    <>
      <Content spacing="small">
        {!readonly && (
          <InputFile
            allowMultiple={true}
            allowedTypes="images"
            getUploadParams={getUploadParams}
            onUploadComplete={handleUploadComplete}
            onUploadProgress={handleUpload}
            onUploadStart={handleUpload}
          />
        )}
        <div className={styles.imageUploads}>
          <Gallery
            files={files}
            size="large"
            onDelete={readonly ? undefined : handleDelete}
          />
        </div>
        {readonly && files.length === 0 && (
          <Text>No gallery images currently exist for this app</Text>
        )}
        <Text variation="subdued">
          Max 4 images, .PNG or .JPEG only, maximum size 1MB
        </Text>
      </Content>
    </>
  );
}
