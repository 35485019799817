import React, { useState } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import {
  FileUpload,
  InputFile,
  UploadParams,
} from "@jobber/components/InputFile";
import { FormatFile } from "@jobber/components/FormatFile";
import { InputValidation } from "@jobber/components/InputValidation";
import {
  DirectUploadCompleteMutation,
  DirectUploadCreateInput,
  DirectUploadCreateMutation,
} from "@/utils/graphql/types";
import { Action } from "@/features/Application/ApplicationForm/ApplicationFormReducer";
import { FileChecksum } from "@/utils/fileChecksum";
import { ValidationHelpers } from "./ValidationHelpers";
import * as styles from "../ApplicationForm.module.css";

export interface LogoUploadProps {
  logoFile: FileUpload | undefined;
  readonly: boolean;
  dispatch(action: Action): void;
  handleUpload(file: FileUpload): void;
  handleDelete(): void;
  createDirectUpload(
    input: DirectUploadCreateInput,
  ): Promise<DirectUploadCreateMutation | undefined>;
  completeDirectUpload(
    key: string,
  ): Promise<DirectUploadCompleteMutation | undefined>;
}

export const BAD_LOGO_IMAGE = "BAD_LOGO_IMAGE";
export function LogoUpload({
  logoFile,
  readonly,
  dispatch,
  handleUpload,
  handleDelete,
  createDirectUpload,
  completeDirectUpload,
}: LogoUploadProps) {
  const [logoErrorText, setLogoErrorText] = useState<string>();

  function displayError(message: string | undefined) {
    setLogoErrorText(message);
  }

  function calculateChecksum(file: File) {
    return new Promise<string>((resolve, reject) => {
      FileChecksum.create(file, (error, checksum) => {
        /* istanbul ignore next */
        if (error) {
          reject(error);
          return;
        }

        resolve(checksum);
      });
    });
  }

  async function getUploadParams(file: File): Promise<UploadParams> {
    const checksum = await calculateChecksum(file);

    if (
      await ValidationHelpers.ValidateImage({
        file,
        displayError: displayError,
      })
    ) {
      const result = await createDirectUpload({
        checksum: checksum,
        filename: file.name,
        contentType: file.type,
        fileSize: file.size,
      });

      if (result) {
        const directUpload = result.directUploadCreate.directUpload;
        /* istanbul ignore next */
        const url = directUpload?.url || "";
        const fields = directUpload?.parameters.reduce(
          (previousFields, currentField) => {
            return {
              ...previousFields,
              [currentField.name]: currentField.value,
            };
          },
          {},
        );

        return {
          url: url,
          fields: fields,
          key: result.directUploadCreate.directUpload?.id,
          httpMethod: "PUT",
        };
      }

      setLogoErrorText("Error uploading image");
    }

    return {
      url: "",
      key: BAD_LOGO_IMAGE,
    };
  }

  async function handleUploadComplete(file: FileUpload) {
    const result = await completeDirectUpload(file.key);

    if (result) {
      await handleUpload(file);
      dispatch({
        type: "Add logo",
        value: file.key,
      });
    } else {
      displayError("Something went wrong.");
      handleDelete();
    }
  }

  return (
    <>
      <Content spacing="small">
        {((logoFile && !logoFile.key) || !logoFile) && (
          <>
            <InputFile
              allowMultiple={false}
              allowedTypes="images"
              getUploadParams={getUploadParams}
              onUploadComplete={handleUploadComplete}
              onUploadProgress={handleUpload}
              onUploadStart={handleUpload}
            />
            {logoErrorText && <InputValidation message={logoErrorText} />}
          </>
        )}
        {logoFile && logoFile.key && (
          <div className={styles.imageUploads}>
            <FormatFile
              file={logoFile}
              onDelete={readonly ? undefined : handleDelete}
            />
          </div>
        )}
        <Text variation="subdued">
          .PNG or .SVG only, must be 384x384 pixels or larger, maximum file size
          1 MB, image must be perfect square
        </Text>
      </Content>
    </>
  );
}
