import React from "react";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { Divider } from "@jobber/components/Divider";
import { WebHookTopicEnum } from "@/utils/graphql/types";
import { WebHook, WebHookListItem } from "./WebHookListItem";

interface WebHookListProps {
  webHooks: WebHook[];
  readonly: boolean;
  onChange(webHooks: WebHook[]): void;
  onDelete(webhook: WebHook): void;
}

export function WebHookList({
  webHooks,
  readonly,
  onChange,
  onDelete,
}: WebHookListProps) {
  const handleWebHookItemChange = (index: number, newWebHook: WebHook) =>
    onChange(
      webHooks.map((webHook, webHookIndex) =>
        webHookIndex === index ? newWebHook : webHook,
      ),
    );

  const handleWebHookItemDelete = (matchIndex: number) => {
    onDelete(webHooks[matchIndex]);
    onChange(
      webHooks.filter((_value, webHookIndex) => webHookIndex !== matchIndex),
    );
  };

  const handleWebHookItemAdd = () =>
    onChange([...webHooks, { topic: "", url: "" }]);

  const options = Object.keys(WebHookTopicEnum)
    .map(value => ({
      label: value,
      value,
    }))
    .filter(option => !webHooks.some(hook => hook.topic == option.value));

  return (
    <Content spacing="small">
      <Content>
        {webHooks.map((webHook, index) => {
          const handleWebHookItemTopicChange = (topic: WebHookTopicEnum) =>
            handleWebHookItemChange(index, { ...webHook, topic });

          const handleWebHookItemUrlChange = (url: string) =>
            handleWebHookItemChange(index, { ...webHook, url });

          const handleDelete = () => handleWebHookItemDelete(index);

          return (
            <>
              {index !== 0 && <Divider />}
              <WebHookListItem
                readonly={readonly}
                availableOptions={options}
                key={index}
                topic={webHook.topic}
                url={webHook.url}
                onTopicChange={handleWebHookItemTopicChange}
                onUrlChange={handleWebHookItemUrlChange}
                onDelete={handleDelete}
              />
            </>
          );
        })}
      </Content>
      {Object.keys(WebHookTopicEnum).length > webHooks.length && !readonly && (
        <Button
          label="Add Webhook"
          variation="work"
          type="secondary"
          size="small"
          onClick={handleWebHookItemAdd}
        />
      )}
    </Content>
  );
}
