/* tslint:disable */
/* eslint-disable */
/* This file is automatically generated and should not be edited. */
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An encoded id */
  EncodedId: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

/** Ability to receive real-time updates when an action occurs within Jobber via POST */
export type AppWebHook = {
  __typename?: "AppWebHook";
  /** The identifier of the app the web hook belongs to */
  appId: Scalars["EncodedId"]["output"];
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** The topic of the app web hook */
  topic: WebHookTopicEnum;
  /** URL to be notified at when the event topic occurs */
  url: Scalars["String"]["output"];
};

/** The connection type for AppWebHook. */
export type AppWebHookConnection = {
  __typename?: "AppWebHookConnection";
  /** A list of edges. */
  edges?: Maybe<Array<AppWebHookEdge>>;
  /** A list of nodes. */
  nodes: Array<AppWebHook>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars["Int"]["output"];
};

/** Autogenerated return type of AppWebHookDestroy. */
export type AppWebHookDestroyPayload = {
  __typename?: "AppWebHookDestroyPayload";
  /** The updated/inserted app web hook */
  appWebHooks?: Maybe<Array<AppWebHook>>;
  /** The errors returned when trying to upsert the app web hook */
  userErrors: Array<MutationErrors>;
};

/** An edge in a connection. */
export type AppWebHookEdge = {
  __typename?: "AppWebHookEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: AppWebHook;
};

/** Attributes for updating/inserting app web hooks */
export type AppWebHookUpdateAttributes = {
  /** The app web hook's app id */
  appId: Scalars["EncodedId"]["input"];
  /** The app web hook's topic */
  topic: WebHookTopicEnum;
  /** The app web hook's url */
  url: Scalars["String"]["input"];
};

/** Autogenerated return type of AppWebHookUpsert. */
export type AppWebHookUpsertPayload = {
  __typename?: "AppWebHookUpsertPayload";
  /** The updated/inserted app web hook */
  appWebHook?: Maybe<AppWebHook>;
  /** The errors returned when trying to upsert the app web hook */
  userErrors: Array<MutationErrors>;
};

/** Applications which improve Jobber's experience */
export type Application = {
  __typename?: "Application";
  /**
   * The app's associated app web hooks
   * @deprecated Use `webhooks` instead
   */
  appWebHooks: AppWebHookConnection;
  /** The scopes that are optional for this application, if any */
  applicationOptionalScopes?: Maybe<Scalars["String"]["output"]>;
  /** The scopes requested for this application */
  applicationScopes: Scalars["String"]["output"];
  /** The display name of the application author */
  author: Scalars["String"]["output"];
  /** The before starting documentation for the application */
  beforeStartingContent: Scalars["String"]["output"];
  /** The client ID of the application for OAuth purposes */
  clientId: Scalars["String"]["output"];
  /** The date time when the application was created */
  createdAt: Scalars["ISO8601DateTime"]["output"];
  /** The description of the application */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The developer center account associated with the application */
  devCenterAccount: DevCenterAccount;
  /** The developer of the application */
  developer: Developer;
  /** The features of the application */
  features?: Maybe<Array<ApplicationFeature>>;
  /** The images of the application for media gallery */
  galleryImages: Array<FileStorage>;
  /** The help articles for the application */
  helpArticles?: Maybe<Array<ApplicationDocumentationLink>>;
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** The installation steps for the application */
  installationStepsContent: Scalars["String"]["output"];
  /** The URL that links to additional information about the application */
  learnMoreUrl: Scalars["String"]["output"];
  /** The logo of the application */
  logo?: Maybe<FileStorage>;
  /** The logo URL to display the logo of the application */
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  /** The URL for the manage app button for an installed App */
  manageAppUrl?: Maybe<Scalars["String"]["output"]>;
  /** The full name of the application */
  name: Scalars["String"]["output"];
  /**
   * The details of the application
   * @deprecated Use fields on Application instead
   */
  oauthApplication: OauthApplication;
  /** The URL that should start the OAuth flow */
  oauthUrl?: Maybe<Scalars["String"]["output"]>;
  /** The original approved/published app for a revision */
  originalApp?: Maybe<Application>;
  /** The URL to the application's privacy policy */
  privacyPolicyUrl?: Maybe<Scalars["String"]["output"]>;
  /** Reauthorize message for changed scopes, if exists */
  reauthorizeMessage?: Maybe<Scalars["String"]["output"]>;
  /** The redirect URL used for OAuth purposes */
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
  /** The resource documentation for the application */
  resources?: Maybe<Array<ApplicationDocumentationLink>>;
  /** The secret of the application for OAuth purposes */
  secret: Scalars["String"]["output"];
  /** The state of the application */
  state: ApplicationStateTypeEnum;
  /** The URL to the application's terms of service */
  termsOfServiceUrl?: Maybe<Scalars["String"]["output"]>;
  /** The date time when the application was last updated */
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  /** The app's associated app web hooks */
  webhooks: AppWebHookConnection;
};

/** Applications which improve Jobber's experience */
export type ApplicationAppWebHooksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Applications which improve Jobber's experience */
export type ApplicationWebhooksArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated return type of ApplicationCancelReview. */
export type ApplicationCancelReviewPayload = {
  __typename?: "ApplicationCancelReviewPayload";
  /** The updated application */
  application?: Maybe<Application>;
  /** The errors returned when trying to modify the state of an application */
  userErrors: Array<MutationErrors>;
};

/** The connection type for Application. */
export type ApplicationConnection = {
  __typename?: "ApplicationConnection";
  /** A list of edges. */
  edges?: Maybe<Array<ApplicationEdge>>;
  /** A list of nodes. */
  nodes: Array<Application>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars["Int"]["output"];
};

/** Autogenerated return type of ApplicationCreateRevision. */
export type ApplicationCreateRevisionPayload = {
  __typename?: "ApplicationCreateRevisionPayload";
  /** The revised application */
  application?: Maybe<Application>;
  /** The errors returned when trying to create the revision */
  userErrors: Array<MutationErrors>;
};

/** Documentation for 3rd party applications */
export type ApplicationDocumentationLink = {
  __typename?: "ApplicationDocumentationLink";
  /** The label to be displayed along the URL */
  label: Scalars["String"]["output"];
  /** The URL to link to */
  url: Scalars["String"]["output"];
};

/** An edge in a connection. */
export type ApplicationEdge = {
  __typename?: "ApplicationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Application;
};

/** Feature provided by an application */
export type ApplicationFeature = {
  __typename?: "ApplicationFeature";
  /** The extended description of the feature. */
  description?: Maybe<Scalars["String"]["output"]>;
  /** The name of the Atlantis icon associated with this feature */
  icon?: Maybe<Scalars["String"]["output"]>;
  /** The label/name of the feature */
  label: Scalars["String"]["output"];
};

/** Attributes for updating/inserting an applications features */
export type ApplicationFeatureAttributes = {
  /** The extended description of the feature. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** The name of the Atlantis icon associated with this feature */
  icon?: InputMaybe<Scalars["String"]["input"]>;
  /** The label/name of the feature */
  label: Scalars["String"]["input"];
};

/** Autogenerated return type of ApplicationRequestReview. */
export type ApplicationRequestReviewPayload = {
  __typename?: "ApplicationRequestReviewPayload";
  /** The updated application */
  application?: Maybe<Application>;
  /** The errors returned when trying to modify the state of an application */
  userErrors: Array<MutationErrors>;
};

export enum ApplicationStateTypeEnum {
  /** The app has been approved and is ready to be published */
  APPROVED = "APPROVED",
  /** The app has been reviewed and requires some changes */
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  /** The app is a draft */
  DRAFT = "DRAFT",
  /** The app is in review */
  IN_REVIEW = "IN_REVIEW",
  /** The app is a draft */
  PUBLISHED = "PUBLISHED",
  /** The app is a published beta app */
  PUBLISHED_BETA = "PUBLISHED_BETA",
  /** The app revision has been reviewed and requires some changes */
  REVISION_CHANGES_REQUESTED = "REVISION_CHANGES_REQUESTED",
  /** The app revision is a draft */
  REVISION_DRAFT = "REVISION_DRAFT",
  /** The app revision is in review */
  REVISION_IN_REVIEW = "REVISION_IN_REVIEW",
}

/** Attributes for updating/inserting applications */
export type ApplicationUpdateAttributes = {
  /** The application's optional scopes */
  applicationOptionalScopes?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's scopes */
  applicationScopes?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's author display name */
  by?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's description */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's features */
  features?: InputMaybe<Array<ApplicationFeatureAttributes>>;
  /** The application's gallery media to add */
  imagesToAdd?: InputMaybe<Array<Scalars["EncodedId"]["input"]>>;
  /** The application's gallery media to remove */
  imagesToRemove?: InputMaybe<Array<Scalars["EncodedId"]["input"]>>;
  /** The unique identifier for the direct upload of the application's logo */
  logoId?: InputMaybe<Scalars["EncodedId"]["input"]>;
  /** The application's URL to manage settings */
  manageAppUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's name */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** The URL to the application's privacy policy */
  privacyPolicyUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Message explaining the last scopes change */
  reauthorizeMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's redirect/callback URL */
  redirectUrls?: InputMaybe<Scalars["String"]["input"]>;
  /** The application's option to turn refresh token rotation on or off */
  refreshTokenRotation?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The URL to the application's terms of service */
  termsOfServiceUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Developer Center accounts are groups of Developer Center users who create apps for augmenting or integrating with Jobber */
export type DevCenterAccount = {
  __typename?: "DevCenterAccount";
  /** The owner of the developer center account */
  accountOwner: DevCenterUser;
  /** The current dev center user */
  currentDevCenterUser: DevCenterUser;
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** The state of the account */
  state: DevCenterAccountStatus;
};

export enum DevCenterAccountStatus {
  ALLOWED = "ALLOWED",
  BLOCKED = "BLOCKED",
}

/** Developers create applications which add functionality to Jobber */
export type DevCenterUser = {
  __typename?: "DevCenterUser";
  /** The Cognito ID of the developer center user */
  cognitoId: Scalars["String"]["output"];
  /** The email of the developer center user */
  email: Scalars["String"]["output"];
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** Whether the user is the account owner */
  isAccountOwner: Scalars["Boolean"]["output"];
  /** Whether the user has MFA enabled */
  mfaEnabled: Scalars["Boolean"]["output"];
  /** The name of the developer center user */
  name: Scalars["String"]["output"];
};

/** The connection type for DevCenterUser. */
export type DevCenterUserConnection = {
  __typename?: "DevCenterUserConnection";
  /** A list of edges. */
  edges?: Maybe<Array<DevCenterUserEdge>>;
  /** A list of nodes. */
  nodes: Array<DevCenterUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DevCenterUserEdge = {
  __typename?: "DevCenterUserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: DevCenterUser;
};

/** A user invite to include a Dev Center User to a Dev Center Account */
export type DevCenterUserInvite = {
  __typename?: "DevCenterUserInvite";
  /** The email of the Dev Center User */
  email: Scalars["String"]["output"];
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** The name of the Dev Center User */
  name?: Maybe<Scalars["String"]["output"]>;
  /** The status of the invite */
  status: DevCenterUserInviteStatus;
};

/** Autogenerated return type of DevCenterUserInviteCancel. */
export type DevCenterUserInviteCancelPayload = {
  __typename?: "DevCenterUserInviteCancelPayload";
  /** The cancelled dev center user invite */
  devCenterUserInvite?: Maybe<DevCenterUserInvite>;
  /** The errors returned when trying to cancel the invite */
  userErrors: Array<MutationErrors>;
};

/** The connection type for DevCenterUserInvite. */
export type DevCenterUserInviteConnection = {
  __typename?: "DevCenterUserInviteConnection";
  /** A list of edges. */
  edges?: Maybe<Array<DevCenterUserInviteEdge>>;
  /** A list of nodes. */
  nodes: Array<DevCenterUserInvite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /**
   * The total count of possible records in this list. Supports filters.
   * Please use with caution. Using totalCount raises the likelyhood you will be throttled
   */
  totalCount: Scalars["Int"]["output"];
};

/** Attributes for inviting a Dev Center user to a Dev Center account. */
export type DevCenterUserInviteCreateAttributes = {
  /** The dev center user's email */
  email: Scalars["String"]["input"];
  /** The dev center user's name */
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DevCenterUserInviteCreate. */
export type DevCenterUserInviteCreatePayload = {
  __typename?: "DevCenterUserInviteCreatePayload";
  /** The created dev center user invite */
  devCenterUserInvite?: Maybe<DevCenterUserInvite>;
  /** The errors returned when creating a dev center user invite */
  userErrors: Array<MutationErrors>;
};

/** An edge in a connection. */
export type DevCenterUserInviteEdge = {
  __typename?: "DevCenterUserInviteEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: DevCenterUserInvite;
};

export enum DevCenterUserInviteStatus {
  ACTIVE = "ACTIVE",
  INVITATION_PENDING = "INVITATION_PENDING",
}

/** Autogenerated return type of DevCenterUserRemove. */
export type DevCenterUserRemovePayload = {
  __typename?: "DevCenterUserRemovePayload";
  /** The removed dev center user */
  devCenterUser?: Maybe<DevCenterUser>;
  /** The errors returned when trying to remove the dev center user */
  userErrors: Array<MutationErrors>;
};

/** Developers create applications which add functionality to Jobber */
export type Developer = {
  __typename?: "Developer";
  /** The Cognito ID of the developer user */
  cognitoId: Scalars["String"]["output"];
  /** The email of the developer user */
  email: Scalars["String"]["output"];
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
};

/** Represents direct upload credentials */
export type DirectUploadComplete = {
  __typename?: "DirectUploadComplete";
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** Required form field or header parameters */
  parameters: Array<DirectUploadParameter>;
  /** URL to upload the file to */
  url: Scalars["String"]["output"];
};

/** Autogenerated return type of DirectUploadComplete. */
export type DirectUploadCompletePayload = {
  __typename?: "DirectUploadCompletePayload";
  /** URL and parameters required for the direct upload */
  directUpload?: Maybe<DirectUploadComplete>;
  /** The errors returned when trying to find a direct upload */
  userErrors: Array<MutationErrors>;
};

/** Represents direct upload credentials */
export type DirectUploadCreate = {
  __typename?: "DirectUploadCreate";
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** Required form field or header parameters */
  parameters: Array<DirectUploadParameter>;
  /** URL to upload the file to */
  url: Scalars["String"]["output"];
};

/** File information required to prepare a direct upload */
export type DirectUploadCreateInput = {
  /** MD5 file checksum as base64 */
  checksum: Scalars["String"]["input"];
  /** File content type */
  contentType: Scalars["String"]["input"];
  /** File size (bytes) */
  fileSize: Scalars["Int"]["input"];
  /** Original file name */
  filename: Scalars["String"]["input"];
};

/** Autogenerated return type of DirectUploadCreate. */
export type DirectUploadCreatePayload = {
  __typename?: "DirectUploadCreatePayload";
  /** URL and parameters required for the direct upload */
  directUpload?: Maybe<DirectUploadCreate>;
  /** The errors returned when trying to create a direct upload */
  userErrors: Array<MutationErrors>;
};

/** Parameters used when uploading. Should be added as PUT headers. */
export type DirectUploadParameter = {
  __typename?: "DirectUploadParameter";
  /** Parameter name */
  name: Scalars["String"]["output"];
  /** Parameter value */
  value: Scalars["String"]["output"];
};

/** Represents an attached file */
export type FileStorage = {
  __typename?: "FileStorage";
  /** Content type of the attachment */
  contentType: Scalars["String"]["output"];
  /** Byte size of the attachment */
  fileSize: Scalars["Int"]["output"];
  /** File name of the attachment */
  filename: Scalars["String"]["output"];
  /** The unique identifier */
  id: Scalars["EncodedId"]["output"];
  /** URL of the attachment */
  url: Scalars["String"]["output"];
};

/** The developer schema's entry point for all mutation operations. */
export type Mutation = {
  __typename?: "Mutation";
  /** Delete an app web hook */
  appWebHookDestroy: AppWebHookDestroyPayload;
  /** Insert or update an app web hook */
  appWebHookUpsert: AppWebHookUpsertPayload;
  /** Cancels a review request for a third party developer's application */
  applicationCancelReview: ApplicationCancelReviewPayload;
  /** Creates a revision for a third party developer's application */
  applicationCreateRevision: ApplicationCreateRevisionPayload;
  /** Request review for a third party developer's application */
  applicationRequestReview: ApplicationRequestReviewPayload;
  /** Cancel an invitation to join a Dev Center account */
  devCenterUserInviteCancel: DevCenterUserInviteCancelPayload;
  /** Invite a Dev Center user to a Dev Center account */
  devCenterUserInviteCreate: DevCenterUserInviteCreatePayload;
  /** Remove a Dev Center user from a Dev Center account */
  devCenterUserRemove: DevCenterUserRemovePayload;
  /** Indicates that the direct upload has been completed */
  directUploadComplete: DirectUploadCompletePayload;
  /** Creates a URL and parameters required for client side direct uploads */
  directUploadCreate: DirectUploadCreatePayload;
  /** Insert or update the authenticated developer's application */
  upsertApplication: UpsertApplicationPayload;
};

/** The developer schema's entry point for all mutation operations. */
export type MutationAppWebHookDestroyArgs = {
  ids: Array<Scalars["EncodedId"]["input"]>;
};

/** The developer schema's entry point for all mutation operations. */
export type MutationAppWebHookUpsertArgs = {
  attributes: AppWebHookUpdateAttributes;
  id?: InputMaybe<Scalars["EncodedId"]["input"]>;
};

/** The developer schema's entry point for all mutation operations. */
export type MutationApplicationCancelReviewArgs = {
  id: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationApplicationCreateRevisionArgs = {
  id: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationApplicationRequestReviewArgs = {
  id: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationDevCenterUserInviteCancelArgs = {
  devCenterUserInviteId: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationDevCenterUserInviteCreateArgs = {
  input: DevCenterUserInviteCreateAttributes;
};

/** The developer schema's entry point for all mutation operations. */
export type MutationDevCenterUserRemoveArgs = {
  devCenterUserId: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationDirectUploadCompleteArgs = {
  directUploadId: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all mutation operations. */
export type MutationDirectUploadCreateArgs = {
  input: DirectUploadCreateInput;
};

/** The developer schema's entry point for all mutation operations. */
export type MutationUpsertApplicationArgs = {
  attributes: ApplicationUpdateAttributes;
  id?: InputMaybe<Scalars["EncodedId"]["input"]>;
};

/** User errors that are triggered by a mutation */
export type MutationErrors = UserErrorsInterface & {
  __typename?: "MutationErrors";
  /** The message provided for this error. */
  message: Scalars["String"]["output"];
  /** The field that triggered the error. */
  path: Array<Scalars["String"]["output"]>;
};

/** OAuth information for an application */
export type OauthApplication = {
  __typename?: "OauthApplication";
  /** The author display name of the application */
  by: Scalars["String"]["output"];
  /** The description of the application */
  description: Scalars["String"]["output"];
  /** The id of the application */
  id: Scalars["String"]["output"];
  /** The full name of the application */
  name: Scalars["String"]["output"];
  /** The redirect url of the application */
  redirectUrls: Scalars["String"]["output"];
  /** Whether or not the application has enabled refresh token rotation */
  refreshTokenRotation: Scalars["Boolean"]["output"];
  /** The secret of the application */
  secret: Scalars["String"]["output"];
  /** The date time when the application was last updated */
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

/** The developer schema's entry point for all query operations. */
export type Query = {
  __typename?: "Query";
  /** The application created by the authenticated developer user */
  application?: Maybe<Application>;
  /** The applications created by the authenticated developer center user */
  applications: ApplicationConnection;
  /** The developer center account */
  devCenterAccount: DevCenterAccount;
  /** The user invites of the developer center account */
  devCenterUserInvites: DevCenterUserInviteConnection;
  /** The users of the developer center account */
  devCenterUsers: DevCenterUserConnection;
};

/** The developer schema's entry point for all query operations. */
export type QueryApplicationArgs = {
  id: Scalars["EncodedId"]["input"];
};

/** The developer schema's entry point for all query operations. */
export type QueryApplicationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The developer schema's entry point for all query operations. */
export type QueryDevCenterUserInvitesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The developer schema's entry point for all query operations. */
export type QueryDevCenterUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Autogenerated return type of UpsertApplication. */
export type UpsertApplicationPayload = {
  __typename?: "UpsertApplicationPayload";
  /** The updated/inserted application */
  application?: Maybe<Application>;
  /** The errors returned when trying to upsert the application */
  userErrors: Array<MutationErrors>;
};

/** User errors that are triggered */
export type UserErrorsInterface = {
  /** The message provided for this error. */
  message: Scalars["String"]["output"];
  /** The field that triggered the error. */
  path: Array<Scalars["String"]["output"]>;
};

export enum WebHookTopicEnum {
  /** When an app connect */
  APP_CONNECT = "APP_CONNECT",
  /** When an app connect */
  APP_DISCONNECT = "APP_DISCONNECT",
  /** When a client is created */
  CLIENT_CREATE = "CLIENT_CREATE",
  /** When a client is deleted */
  CLIENT_DESTROY = "CLIENT_DESTROY",
  /** When a client is updated */
  CLIENT_UPDATE = "CLIENT_UPDATE",
  /** When an expense is created */
  EXPENSE_CREATE = "EXPENSE_CREATE",
  /** When an expense is deleted */
  EXPENSE_DESTROY = "EXPENSE_DESTROY",
  /** When an expense is updated */
  EXPENSE_UPDATE = "EXPENSE_UPDATE",
  /** When an invoice is created */
  INVOICE_CREATE = "INVOICE_CREATE",
  /** When an invoice is deleted */
  INVOICE_DESTROY = "INVOICE_DESTROY",
  /** When an invoice is updated */
  INVOICE_UPDATE = "INVOICE_UPDATE",
  /** When a job is closed */
  JOB_CLOSED = "JOB_CLOSED",
  /** When a job is created */
  JOB_CREATE = "JOB_CREATE",
  /** When a job is deleted */
  JOB_DESTROY = "JOB_DESTROY",
  /** When a job is updated */
  JOB_UPDATE = "JOB_UPDATE",
  /** When a payment is created */
  PAYMENT_CREATE = "PAYMENT_CREATE",
  /** When a payment is deleted */
  PAYMENT_DESTROY = "PAYMENT_DESTROY",
  /** When a payment is updated */
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  /** When a payout is created */
  PAYOUT_CREATE = "PAYOUT_CREATE",
  /** When a payout is deleted */
  PAYOUT_DESTROY = "PAYOUT_DESTROY",
  /** When a payout is updated */
  PAYOUT_UPDATE = "PAYOUT_UPDATE",
  /** When a product or service is created */
  PRODUCT_OR_SERVICE_CREATE = "PRODUCT_OR_SERVICE_CREATE",
  /** When a product or service is deleted */
  PRODUCT_OR_SERVICE_DESTROY = "PRODUCT_OR_SERVICE_DESTROY",
  /** When a product or service is updated */
  PRODUCT_OR_SERVICE_UPDATE = "PRODUCT_OR_SERVICE_UPDATE",
  /** When a property is created */
  PROPERTY_CREATE = "PROPERTY_CREATE",
  /** When a property is deleted */
  PROPERTY_DESTROY = "PROPERTY_DESTROY",
  /** When a property is updated */
  PROPERTY_UPDATE = "PROPERTY_UPDATE",
  /** When a quote is approved */
  QUOTE_APPROVED = "QUOTE_APPROVED",
  /** When a quote is created */
  QUOTE_CREATE = "QUOTE_CREATE",
  /** When a quote is deleted */
  QUOTE_DESTROY = "QUOTE_DESTROY",
  /** When a quote is sent */
  QUOTE_SENT = "QUOTE_SENT",
  /** When a quote is updated */
  QUOTE_UPDATE = "QUOTE_UPDATE",
  /** When a request is created */
  REQUEST_CREATE = "REQUEST_CREATE",
  /** When a request is deleted */
  REQUEST_DESTROY = "REQUEST_DESTROY",
  /** When a request is updated */
  REQUEST_UPDATE = "REQUEST_UPDATE",
  /** When a timesheet is created */
  TIMESHEET_CREATE = "TIMESHEET_CREATE",
  /** When a timesheet is deleted */
  TIMESHEET_DESTROY = "TIMESHEET_DESTROY",
  /** When a timesheet is updated */
  TIMESHEET_UPDATE = "TIMESHEET_UPDATE",
  /** When a visit is completed */
  VISIT_COMPLETE = "VISIT_COMPLETE",
  /** When a visit is created. When multiple visits are created in a recurring schedule, only the first visit will notify */
  VISIT_CREATE = "VISIT_CREATE",
  /** When a visit is deleted */
  VISIT_DESTROY = "VISIT_DESTROY",
  /** When a visit is updated */
  VISIT_UPDATE = "VISIT_UPDATE",
}

export type OauthApplicationFieldsFragment = {
  __typename?: "Application";
  oauthApplication: {
    __typename?: "OauthApplication";
    id: string;
    name: string;
    description: string;
    by: string;
    redirectUrls: string;
    secret: string;
    refreshTokenRotation: boolean;
  };
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars["EncodedId"]["input"];
}>;

export type GetApplicationQuery = {
  __typename?: "Query";
  application?:
    | {
        __typename?: "Application";
        id: string;
        state: ApplicationStateTypeEnum;
        applicationScopes: string;
        applicationOptionalScopes?: string | undefined;
        reauthorizeMessage?: string | undefined;
        manageAppUrl?: string | undefined;
        termsOfServiceUrl?: string | undefined;
        privacyPolicyUrl?: string | undefined;
        features?:
          | Array<{ __typename?: "ApplicationFeature"; label: string }>
          | undefined;
        galleryImages: Array<{
          __typename?: "FileStorage";
          id: string;
          url: string;
          filename: string;
          fileSize: number;
          contentType: string;
        }>;
        logo?:
          | {
              __typename?: "FileStorage";
              id: string;
              url: string;
              filename: string;
              fileSize: number;
              contentType: string;
            }
          | undefined;
        appWebHooks: {
          __typename?: "AppWebHookConnection";
          nodes: Array<{
            __typename?: "AppWebHook";
            id: string;
            topic: WebHookTopicEnum;
            url: string;
          }>;
        };
        originalApp?:
          | {
              __typename?: "Application";
              applicationScopes: string;
              applicationOptionalScopes?: string | undefined;
            }
          | undefined;
        oauthApplication: {
          __typename?: "OauthApplication";
          id: string;
          name: string;
          description: string;
          by: string;
          redirectUrls: string;
          secret: string;
          refreshTokenRotation: boolean;
        };
      }
    | undefined;
};

export type UpsertApplicationMutationVariables = Exact<{
  attributes: ApplicationUpdateAttributes;
  id?: InputMaybe<Scalars["EncodedId"]["input"]>;
}>;

export type UpsertApplicationMutation = {
  __typename?: "Mutation";
  upsertApplication: {
    __typename?: "UpsertApplicationPayload";
    application?:
      | {
          __typename?: "Application";
          id: string;
          state: ApplicationStateTypeEnum;
          applicationScopes: string;
          applicationOptionalScopes?: string | undefined;
          reauthorizeMessage?: string | undefined;
          manageAppUrl?: string | undefined;
          termsOfServiceUrl?: string | undefined;
          privacyPolicyUrl?: string | undefined;
          features?:
            | Array<{ __typename?: "ApplicationFeature"; label: string }>
            | undefined;
          galleryImages: Array<{
            __typename?: "FileStorage";
            id: string;
            url: string;
            filename: string;
            fileSize: number;
            contentType: string;
          }>;
          logo?:
            | {
                __typename?: "FileStorage";
                id: string;
                url: string;
                filename: string;
                fileSize: number;
                contentType: string;
              }
            | undefined;
          appWebHooks: {
            __typename?: "AppWebHookConnection";
            nodes: Array<{
              __typename?: "AppWebHook";
              id: string;
              topic: WebHookTopicEnum;
              url: string;
            }>;
          };
          oauthApplication: {
            __typename?: "OauthApplication";
            id: string;
            name: string;
            description: string;
            by: string;
            redirectUrls: string;
            secret: string;
            refreshTokenRotation: boolean;
          };
        }
      | undefined;
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type UpsertWebhookMutationVariables = Exact<{
  attributes: AppWebHookUpdateAttributes;
  id?: InputMaybe<Scalars["EncodedId"]["input"]>;
}>;

export type UpsertWebhookMutation = {
  __typename?: "Mutation";
  appWebHookUpsert: {
    __typename?: "AppWebHookUpsertPayload";
    appWebHook?:
      | {
          __typename?: "AppWebHook";
          id: string;
          topic: WebHookTopicEnum;
          url: string;
        }
      | undefined;
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type DestroyWebhookMutationVariables = Exact<{
  ids: Array<Scalars["EncodedId"]["input"]> | Scalars["EncodedId"]["input"];
}>;

export type DestroyWebhookMutation = {
  __typename?: "Mutation";
  appWebHookDestroy: {
    __typename?: "AppWebHookDestroyPayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type DirectUploadCreateMutationVariables = Exact<{
  input: DirectUploadCreateInput;
}>;

export type DirectUploadCreateMutation = {
  __typename?: "Mutation";
  directUploadCreate: {
    __typename?: "DirectUploadCreatePayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      path: Array<string>;
      message: string;
    }>;
    directUpload?:
      | {
          __typename?: "DirectUploadCreate";
          url: string;
          id: string;
          parameters: Array<{
            __typename?: "DirectUploadParameter";
            value: string;
            name: string;
          }>;
        }
      | undefined;
  };
};

export type DirectUploadCompleteMutationVariables = Exact<{
  id: Scalars["EncodedId"]["input"];
}>;

export type DirectUploadCompleteMutation = {
  __typename?: "Mutation";
  directUploadComplete: {
    __typename?: "DirectUploadCompletePayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      path: Array<string>;
      message: string;
    }>;
    directUpload?:
      | {
          __typename?: "DirectUploadComplete";
          url: string;
          id: string;
          parameters: Array<{
            __typename?: "DirectUploadParameter";
            value: string;
            name: string;
          }>;
        }
      | undefined;
  };
};

export type GetApplicationsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetApplicationsQuery = {
  __typename?: "Query";
  applications: {
    __typename?: "ApplicationConnection";
    totalCount: number;
    edges?:
      | Array<{
          __typename?: "ApplicationEdge";
          cursor: string;
          node: {
            __typename?: "Application";
            id: string;
            state: ApplicationStateTypeEnum;
            logo?: { __typename?: "FileStorage"; url: string } | undefined;
            oauthApplication: {
              __typename?: "OauthApplication";
              id: string;
              name: string;
              secret: string;
            };
          };
        }>
      | undefined;
    pageInfo: {
      __typename?: "PageInfo";
      endCursor?: string | undefined;
      startCursor?: string | undefined;
      hasNextPage: boolean;
    };
  };
};

export type ApplicationCancelReviewMutationVariables = Exact<{
  id: Scalars["EncodedId"]["input"];
}>;

export type ApplicationCancelReviewMutation = {
  __typename?: "Mutation";
  applicationCancelReview: {
    __typename?: "ApplicationCancelReviewPayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type ApplicationRequestReviewMutationVariables = Exact<{
  id: Scalars["EncodedId"]["input"];
}>;

export type ApplicationRequestReviewMutation = {
  __typename?: "Mutation";
  applicationRequestReview: {
    __typename?: "ApplicationRequestReviewPayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type ApplicationCreateRevisionMutationVariables = Exact<{
  id: Scalars["EncodedId"]["input"];
}>;

export type ApplicationCreateRevisionMutation = {
  __typename?: "Mutation";
  applicationCreateRevision: {
    __typename?: "ApplicationCreateRevisionPayload";
    application?:
      | {
          __typename?: "Application";
          applicationScopes: string;
          author: string;
          clientId: string;
          description?: string | undefined;
          name: string;
          id: string;
          secret: string;
          state: ApplicationStateTypeEnum;
        }
      | undefined;
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type GetDevCenterAccountQueryVariables = Exact<{ [key: string]: never }>;

export type GetDevCenterAccountQuery = {
  __typename?: "Query";
  devCenterAccount: {
    __typename?: "DevCenterAccount";
    currentDevCenterUser: {
      __typename?: "DevCenterUser";
      id: string;
      isAccountOwner: boolean;
    };
  };
};

export type DevCenterUserInviteCancelMutationVariables = Exact<{
  devCenterUserInviteId: Scalars["EncodedId"]["input"];
}>;

export type DevCenterUserInviteCancelMutation = {
  __typename?: "Mutation";
  devCenterUserInviteCancel: {
    __typename?: "DevCenterUserInviteCancelPayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type CreateDevCenterUserInviteMutationVariables = Exact<{
  input: DevCenterUserInviteCreateAttributes;
}>;

export type CreateDevCenterUserInviteMutation = {
  __typename?: "Mutation";
  devCenterUserInviteCreate: {
    __typename?: "DevCenterUserInviteCreatePayload";
    userErrors: Array<{
      __typename?: "MutationErrors";
      message: string;
      path: Array<string>;
    }>;
  };
};

export type RemoveDevCenterUserMutationVariables = Exact<{
  devCenterUserId: Scalars["EncodedId"]["input"];
}>;

export type RemoveDevCenterUserMutation = {
  __typename?: "Mutation";
  devCenterUserRemove: {
    __typename?: "DevCenterUserRemovePayload";
    userErrors: Array<{ __typename?: "MutationErrors"; message: string }>;
  };
};

export type GetTeamMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GetTeamMembersQuery = {
  __typename?: "Query";
  devCenterUsers: {
    __typename?: "DevCenterUserConnection";
    nodes: Array<{
      __typename?: "DevCenterUser";
      id: string;
      email: string;
      name: string;
      isAccountOwner: boolean;
    }>;
  };
  devCenterUserInvites: {
    __typename?: "DevCenterUserInviteConnection";
    nodes: Array<{
      __typename?: "DevCenterUserInvite";
      id: string;
      email: string;
      name?: string | undefined;
      status: DevCenterUserInviteStatus;
    }>;
  };
};
