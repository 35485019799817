import React from "react";
import { InputText } from "@jobber/components/InputText";
import { Button } from "@jobber/components/Button";
import * as styles from "./FeatureListItem.module.css";

export interface FeatureListItemProps {
  label: string;
  readonly: boolean;
  canDelete?: boolean;
  onLabelChange(label: string): void;
  onDelete(): void;
}

export function FeatureListItem({
  label,
  readonly,
  canDelete,
  onLabelChange,
  onDelete,
}: FeatureListItemProps) {
  return (
    <div className={styles.line_item}>
      <InputText
        placeholder="Feature or benefit description"
        readonly={readonly}
        validations={{
          maxLength: {
            value: 125,
            message: "Max 125 characters",
          },
        }}
        value={label}
        size="small"
        onChange={onLabelChange}
      />
      {canDelete && !readonly && (
        <Button
          icon="trash"
          type="tertiary"
          variation="destructive"
          ariaLabel="Delete feature line item"
          onClick={onDelete}
        />
      )}
    </div>
  );
}
