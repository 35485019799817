import SparkMD5 from "spark-md5";

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

type FileChecksumCallback = (error: string | null, checksum: string) => void;

interface FileReaderEventTarget extends EventTarget {
  result: ArrayBuffer;
}

interface FileReaderEvent extends Event {
  target: FileReaderEventTarget;
}

export class FileChecksum {
  file: File;
  chunkSize: number;
  chunkCount: number;
  chunkIndex: number;
  callback?: FileChecksumCallback;
  md5Buffer?: SparkMD5.ArrayBuffer;
  fileReader?: FileReader;

  static create(file: File, callback: FileChecksumCallback) {
    const instance = new FileChecksum(file);
    instance.create(callback);
  }

  constructor(file: File) {
    this.file = file;
    this.chunkSize = 2097152; // 2MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize);
    this.chunkIndex = 0;
  }

  create(callback: FileChecksumCallback) {
    this.callback = callback;
    this.md5Buffer = new SparkMD5.ArrayBuffer();
    this.fileReader = new FileReader();
    this.fileReader.addEventListener(
      "load",
      (event: ProgressEvent<FileReader>) =>
        this.fileReaderDidLoad(event as FileReaderEvent),
    );
    this.fileReader.addEventListener("error", () => this.fileReaderDidError());
    this.readNextChunk();
  }

  fileReaderDidLoad(event: FileReaderEvent) {
    this.md5Buffer?.append(event.target.result);

    if (!this.readNextChunk() && this.md5Buffer) {
      const binaryDigest = this.md5Buffer.end(true);
      const base64digest = btoa(binaryDigest);
      this.callback?.(null, base64digest);
    }
  }

  fileReaderDidError() {
    this.callback?.(`Error reading ${this.file.name}`, "");
  }

  readNextChunk() {
    if (
      this.chunkIndex < this.chunkCount ||
      (this.chunkIndex == 0 && this.chunkCount == 0)
    ) {
      const start = this.chunkIndex * this.chunkSize;
      const end = Math.min(start + this.chunkSize, this.file.size);
      const bytes = this.file.slice(start, end);
      this.fileReader?.readAsArrayBuffer(bytes);
      this.chunkIndex++;
      return true;
    } else {
      return false;
    }
  }
}
