import React, { useEffect, useState } from "react";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import { Heading } from "@jobber/components/Heading";
import { InputText } from "@jobber/components/InputText";
import { isEqual } from "lodash";
import { ApplicationStateTypeEnum } from "@/utils/graphql/types";
import {
  isAppEditable,
  isAppRevisable,
  isAppRevision,
} from "@/utils/applicationStatus";
import { ScopeItem, ScopeItemType } from "./ScopeItem";
import { ScopeCollectionManipulators } from "./ScopeCollectionManipulators";
import { ScopeCollection } from "./initialState";

export interface ScopesFormProps {
  onScopesChange(newValue: ScopeCollection): void;
  onReauthorizationMessageChange(newValue: string): void;
  scopes: ScopeCollection;
  originalScopes: ScopeCollection;
  reauthorizeMessageValue?: string;
  readonly: boolean;
  applicationState: ApplicationStateTypeEnum;
}

export function ScopesForm({
  onScopesChange,
  onReauthorizationMessageChange,
  scopes,
  originalScopes,
  reauthorizeMessageValue,
  readonly,
  applicationState,
}: ScopesFormProps) {
  const { handleChange, convertToString } = ScopeCollectionManipulators();

  const [scopesState, setScopesState] = useState(scopes);
  const [dirtyFlag, setDirtyFlag] = useState(
    !isEqual(convertToString(originalScopes), convertToString(scopes)),
  );

  useEffect(() => {
    setScopesState(scopes);
  }, [scopes]);

  useEffect(() => {
    setDirtyFlag(
      !isEqual(convertToString(originalScopes), convertToString(scopes)),
    );
  }, [Object.values(scopesState)]);

  const showReauthorizationMessage =
    (reauthorizeMessageValue && isAppRevisable(applicationState)) ||
    (dirtyFlag && isAppRevision(applicationState));

  const handleScopeFormChange = (scopeItem: ScopeItemType) => {
    handleChange(scopesState, scopeItem);
    onScopesChange(scopesState);
  };

  return (
    <>
      <Text>
        Choose the objects and actions that you want your app to have access to
      </Text>
      <Divider />
      {Object.keys(scopesState).map((scopeKey, index) => {
        return (
          <div key={scopeKey}>
            <ScopeItem
              readonly={readonly}
              item={scopesState[scopeKey]}
              key={scopeKey}
              onChange={handleScopeFormChange}
            />
            {(index < Object.keys(scopesState).length - 1 ||
              showReauthorizationMessage) && <Divider />}
          </div>
        );
      })}
      {showReauthorizationMessage && (
        <>
          <Heading level={4}>Reasons for scope change</Heading>
          <Text>
            To proceed with the additional scopes, any Jobber accounts that are
            connected to your app will need to re-authorize. Enter a message
            below to explain why your app requires additional scopes. (This
            message will need to be approved by Jobber)
          </Text>
          <InputText
            multiline
            readonly={!isAppEditable(applicationState)}
            value={reauthorizeMessageValue}
            onChange={onReauthorizationMessageChange}
            placeholder="Reasons for scope change"
            validations={{
              required: {
                value: true,
                message: "Input reasons for scope change",
              },
            }}
          />
          <Text>Max 300 characters</Text>
        </>
      )}
    </>
  );
}
