import React from "react";
import { navigate } from "gatsby";
import { Banner } from "@jobber/components/Banner";

export function MFABanner() {
  return (
    <Banner
      type="warning"
      primaryAction={{
        label: "Set Up Now",
        onClick: () => navigate("/settings"),
      }}
      dismissible={false}
    >
      We recommend setting up two-factor authentication to make your account
      more secure
    </Banner>
  );
}
