import { ApplicationStateTypeEnum } from "@/utils/graphql/types";

export const EDITABLE_APPLICATION_STATES = [
  ApplicationStateTypeEnum.DRAFT,
  ApplicationStateTypeEnum.REVISION_DRAFT,
  ApplicationStateTypeEnum.CHANGES_REQUESTED,
  ApplicationStateTypeEnum.REVISION_CHANGES_REQUESTED,
];

export const READONLY_APPLICATION_STATES = [
  ApplicationStateTypeEnum.APPROVED,
  ApplicationStateTypeEnum.PUBLISHED,
  ApplicationStateTypeEnum.IN_REVIEW,
  ApplicationStateTypeEnum.REVISION_IN_REVIEW,
];

export const REVISIBLE_APPLICATION_STATES = [
  ApplicationStateTypeEnum.APPROVED,
  ApplicationStateTypeEnum.PUBLISHED,
  ApplicationStateTypeEnum.PUBLISHED_BETA,
];

export const REVISION_APPLICATION_STATES = [
  ApplicationStateTypeEnum.REVISION_DRAFT,
  ApplicationStateTypeEnum.REVISION_CHANGES_REQUESTED,
  ApplicationStateTypeEnum.REVISION_IN_REVIEW,
];

export const IN_REVIEW_APPLICATION_STATES = [
  ApplicationStateTypeEnum.IN_REVIEW,
  ApplicationStateTypeEnum.REVISION_IN_REVIEW,
];

export function isAppInReview(status: ApplicationStateTypeEnum) {
  return IN_REVIEW_APPLICATION_STATES.includes(status);
}

export function isAppEditable(status: ApplicationStateTypeEnum) {
  return EDITABLE_APPLICATION_STATES.includes(status);
}

export function isAppRevision(status: ApplicationStateTypeEnum) {
  return REVISION_APPLICATION_STATES.includes(status);
}

export function isAppRevisable(status: ApplicationStateTypeEnum) {
  return REVISIBLE_APPLICATION_STATES.includes(status);
}
