import React, { useEffect } from "react";
import { useCollectionQuery } from "@jobber/hooks";
import { useMutation } from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import {
  ApplicationCancelReviewMutation,
  ApplicationCancelReviewMutationVariables,
  ApplicationCreateRevisionMutation,
  ApplicationCreateRevisionMutationVariables,
  ApplicationRequestReviewMutation,
  GetApplicationsQuery,
} from "@/utils/graphql/types";
import { useDisplayError } from "@/hooks/useDisplayError";
import { ApplicationList } from "./ApplicationList";
import {
  APPLICATION_CANCEL_REVIEW,
  APPLICATION_CREATE_REVISION,
  APPLICATION_REQUEST_REVIEW,
  GET_APPLICATIONS,
} from "./ApplicationList.graphql";

export function ApplicationListLoader() {
  const { data, refresh, loadingInitialContent } =
    useCollectionQuery<GetApplicationsQuery>({
      query: GET_APPLICATIONS,
      queryOptions: {
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first",
      },
      getCollectionByPath(items) {
        return items?.applications;
      },
    });

  const { displayError } = useDisplayError();

  const [cancelReview, { error: cancelReviewError }] = useMutation<
    ApplicationCancelReviewMutation,
    ApplicationCancelReviewMutationVariables
  >(APPLICATION_CANCEL_REVIEW, {
    onCompleted: response => {
      const applicationCancelReview = response?.applicationCancelReview;
      const userErrors = applicationCancelReview?.userErrors;

      if (userErrors && userErrors.length > 0) {
        displayError(userErrors.map(error => error.message));
      } else {
        showToast({ message: "Review request cancelled" });
        refresh();
      }
    },
  });

  const [createApplicationRevision, { error: createApplicationRevisionError }] =
    useMutation<
      ApplicationCreateRevisionMutation,
      ApplicationCreateRevisionMutationVariables
    >(APPLICATION_CREATE_REVISION, {
      onCompleted: response => {
        const applicationCreateRevision = response?.applicationCreateRevision;
        const userErrors = applicationCreateRevision?.userErrors;

        if (applicationCreateRevision?.application) {
          refresh();
          showToast({ message: "Revision successfully created" });
        } else if (userErrors && userErrors.length > 0) {
          displayError(userErrors.map(error => error.message));
        }
      },
    });

  const [requestReview, { error: requestReviewError }] = useMutation<
    ApplicationRequestReviewMutation,
    ApplicationCancelReviewMutationVariables
  >(APPLICATION_REQUEST_REVIEW, {
    onCompleted: response => {
      const applicationRequestReview = response.applicationRequestReview;
      const userErrors = applicationRequestReview?.userErrors;

      if (userErrors && userErrors.length > 0) {
        displayError(userErrors.map(error => error.message));
      } else {
        refresh();
        showToast({
          message:
            "Review requested. You'll receive an email when it's complete.",
        });
      }
    },
  });

  // display mutation errors since onError does not work
  useEffect(() => {
    const message =
      createApplicationRevisionError?.message ||
      requestReviewError?.message ||
      cancelReviewError?.message;

    if (message) {
      displayError([message]);
    }
  }, [createApplicationRevisionError, requestReviewError, cancelReviewError]);

  return (
    <ApplicationList
      items={
        data?.applications?.edges?.map(app => {
          const {
            id,
            state,
            logo,
            oauthApplication: { name, id: clientId, secret },
          } = app.node;
          return {
            id,
            name,
            clientId,
            state,
            secret,
            logoUrl: logo?.url,
            onReviewCancel: () => cancelReview({ variables: { id } }),
            onReviewRequest: () => requestReview({ variables: { id } }),
            onCreateRevision: () =>
              createApplicationRevision({ variables: { id } }),
          };
        }) || []
      }
      loading={loadingInitialContent}
    />
  );
}
