import React from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Cell, Header, Table } from "@jobber/components/Table";
import { Spinner } from "@jobber/components/Spinner";
import * as styles from "./ApplicationList.module.css";
import {
  ApplicationListItem,
  ApplicationListItemData,
} from "./ApplicationListItem";

interface ApplicationListProps {
  items: ApplicationListItemData[];
  loading: boolean;
}
export function ApplicationList({ items, loading }: ApplicationListProps) {
  return (
    <Content>
      {loading ? (
        <Spinner />
      ) : items.length > 0 ? (
        <div className={styles.applicationList}>
          <Table>
            <Header>
              <Cell>Name</Cell>
              <Cell>Status</Cell>
              <Cell align="right">Actions</Cell>
            </Header>
            <tbody>
              {items.map(application => (
                <ApplicationListItem key={application.id} {...application} />
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Text variation="subdued">{"You haven't created any apps yet."}</Text>
      )}
    </Content>
  );
}
