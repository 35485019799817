import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { Button } from "@jobber/components/Button";
import { Menu } from "@jobber/components/Menu";
import { Cell, Row } from "@jobber/components/Table";
import { RequestReviewModal } from "@/features/Application/ApplicationList/RequestReviewModal";
import { MFAModal } from "@/features/Application/ApplicationList/MFAModal";
import { useUserContext } from "@/context/user";
import { isAppInReview, isAppRevision } from "@/utils/applicationStatus";
import { useClipboardActions } from "@/hooks/useClipboardActions";
import { ApplicationStatus } from "@/features/Application/ApplicationStatus";
import {
  Application,
  ApplicationStateTypeEnum,
  OauthApplication,
} from "@/utils/graphql/types";
import { useApplicationActions } from "@/features/Application/hooks/useApplicationActions";
import { useDisplayError } from "@/hooks/useDisplayError";
import * as styles from "./ApplicationListItem.module.css";

export interface ApplicationListItemData
  extends Pick<Application, "id" | "state" | "logoUrl">,
    Pick<OauthApplication, "name" | "secret"> {
  clientId: string;
  onReviewCancel(): void;
  onReviewRequest(): void;
  onCreateRevision(): void;
}

export type ApplicationListItemProps = ApplicationListItemData;

export function ApplicationListItem({
  id,
  name,
  state,
  logoUrl,
  clientId,
  secret,
  onReviewCancel,
  onReviewRequest,
  onCreateRevision,
}: ApplicationListItemProps) {
  const { copyText } = useClipboardActions();

  const { MFAEnabled } = useUserContext();
  const [MFAModalOpen, setMFAModalOpen] = useState(false);

  const { displayError } = useDisplayError();

  const [isRequestReviewModalOpen, setIsRequestReviewModalOpen] =
    useState(false);

  const actions = useApplicationActions({
    onEditClick: onEdit,
    onCreateRevisionClick: onCreateRevision,
    onClientSecretCopyClick: /* istanbul ignore next */ () => copyText(secret), // Ignored to prevent coverage issues
    onClientIDCopyClick: /* istanbul ignore next */ () => copyText(clientId), // Ignored to prevent coverage issues
    onGraphiQLClick: openGraphiQL,
    onCancelReviewClick: onReviewCancel,
    onReviewRequestClick: handleReviewRequest,
    status: state,
  });

  return (
    <Row>
      <MFAModal
        open={MFAModalOpen}
        onRequestClose={(val: boolean) => setMFAModalOpen(val)}
      />
      <RequestReviewModal
        open={isRequestReviewModalOpen}
        onSubmitReviewRequest={onReviewRequest}
        onRequestClose={(val: boolean) => setIsRequestReviewModalOpen(val)}
      />
      <Cell>
        <LinktoApp />
      </Cell>
      <Cell>
        <ApplicationStatus status={state} />
      </Cell>
      <Cell align="right">
        <Menu
          activator={
            <Button type="tertiary" icon="more" ariaLabel="More Actions" />
          }
          items={[
            {
              actions: actions,
            },
          ]}
        />
      </Cell>
    </Row>
  );

  function LinktoApp() {
    if (isAppRevision(state)) {
      return (
        <div className={styles.appName}>
          <div className={styles.arrowToApp}> &#8627;</div>
          <Link to={`/apps/${id}`}>{name}</Link>
        </div>
      );
    }
    return (
      <div>
        <Link to={`/apps/${id}`}>{name}</Link>
      </div>
    );
  }

  function handleReviewRequest() {
    if (!MFAEnabled) {
      setMFAModalOpen(true);
    } else if (!logoUrl) {
      displayError([
        "Can not request review. App needs a logo for review. Upload an app logo then try again.",
      ]);
    } else if (state === ApplicationStateTypeEnum.DRAFT) {
      // The modal will call `onReviewRequest` when the user confirms completing the Pre-Submission Checklist.
      setIsRequestReviewModalOpen(true);
    } else {
      onReviewRequest();
    }
  }

  function openGraphiQL() {
    localStorage.setItem("clientId", clientId);
    localStorage.setItem("clientSecret", secret);
    localStorage.removeItem("refreshToken");
    window.location.href = `${process.env.GATSBY_JOBBER_URL}/api/oauth/authorize?client_id=${clientId}&redirect_uri=${location.origin}/apps/test`;
  }

  function onEdit() {
    if (isAppInReview(state)) {
      displayError([
        "Can not edit an app in review. To edit, cancel review request.",
      ]);
    } else {
      navigate(`/apps/${id}`);
    }
  }
}
