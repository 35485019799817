import { gql } from "@apollo/client";

export const GET_APPLICATIONS = gql`
  query GetApplications($cursor: String) {
    applications(after: $cursor) {
      edges {
        node {
          id
          state
          logo {
            url
          }
          oauthApplication {
            id
            name
            secret
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasNextPage
      }
    }
  }
`;

export const APPLICATION_CANCEL_REVIEW = gql`
  mutation ApplicationCancelReview($id: EncodedId!) {
    applicationCancelReview(id: $id) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const APPLICATION_REQUEST_REVIEW = gql`
  mutation ApplicationRequestReview($id: EncodedId!) {
    applicationRequestReview(id: $id) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const APPLICATION_CREATE_REVISION = gql`
  mutation ApplicationCreateRevision($id: EncodedId!) {
    applicationCreateRevision(id: $id) {
      application {
        applicationScopes
        author
        clientId
        description
        name
        id
        secret
        state
      }
      userErrors {
        message
        path
      }
    }
  }
`;
