import { ActionProps } from "@jobber/components/Menu";
import { useMemo } from "react";
import { ApplicationStateTypeEnum } from "@/utils/graphql/types";
import {
  isAppEditable,
  isAppInReview,
  isAppRevisable,
} from "@/utils/applicationStatus";

interface UseApplicationActionsProps {
  onEditClick?(): void;
  onCreateRevisionClick?(): void;
  onClientSecretCopyClick(): void;
  onClientIDCopyClick(): void;
  onGraphiQLClick(): void;
  onCancelReviewClick(): void;
  onReviewRequestClick(): void;
  status: ApplicationStateTypeEnum;
  includeEditAction?: boolean;
}

export function useApplicationActions({
  onEditClick,
  onCreateRevisionClick,
  onClientSecretCopyClick,
  onClientIDCopyClick,
  onGraphiQLClick,
  onCancelReviewClick,
  onReviewRequestClick,
  status,
  includeEditAction = true,
}: UseApplicationActionsProps) {
  const canCancelReview = isAppInReview(status);
  const canCreateRevision = isAppRevisable(status);
  const canSeeReviewActions = isAppEditable(status) || isAppInReview(status);

  const actions = useMemo((): ActionProps[] => {
    const defaultActions: ActionProps[] = [
      { label: "Copy client ID", icon: "copy", onClick: onClientIDCopyClick },
      {
        label: "Copy client secret",
        icon: "copy",
        onClick: onClientSecretCopyClick,
      },
      {
        label: "Test in GraphiQL",
        icon: "chemical",
        onClick: onGraphiQLClick,
      },
    ];
    const editAction: ActionProps = canCreateRevision
      ? {
          label: "Create a revision",
          icon: "addNote",
          onClick: onCreateRevisionClick,
        }
      : {
          label: "Edit",
          icon: "edit",
          onClick: onEditClick,
        };
    const reviewAction: ActionProps = canCancelReview
      ? {
          label: "Cancel review",
          icon: "remove",
          onClick: onCancelReviewClick,
        }
      : {
          label: "Request a review",
          icon: "customize",
          onClick: onReviewRequestClick,
        };

    const actionOptions: ActionProps[] = [...defaultActions];
    if (includeEditAction) {
      actionOptions.unshift(editAction);
    }
    if (canSeeReviewActions) {
      actionOptions.push(reviewAction);
    }

    return actionOptions;
  }, [status]);

  return actions;
}
