import React from "react";
import { navigate } from "gatsby";
import { Modal } from "@jobber/components/Modal";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";

interface MFAModalProps {
  open: boolean;
  onRequestClose(val: boolean): void;
}
export function MFAModal({ open, onRequestClose }: MFAModalProps) {
  return (
    <Modal
      title="Set up Two-Factor Authentication"
      open={open}
      onRequestClose={() => onRequestClose(false)}
      size="small"
      primaryAction={{
        label: "Set Up Now",
        onClick: () => navigate("/settings"),
      }}
      secondaryAction={{
        label: "Cancel",
        onClick: () => {
          onRequestClose(false);
        },
      }}
    >
      <Content>
        <Text>
          Keeping your account secure is our priority. Set up two-factor
          authentication before you request a review of your app.
        </Text>
      </Content>
    </Modal>
  );
}
