export interface ErrorProps {
  file: File;
  displayError(message: string | undefined): void;
}

const FILE_SIZE_TOO_LARGE =
  "Can't upload image. Image file size is too large. Upload an image file that is less than 1MB.";
const INCORRECT_FILE_TYPE =
  "Can't upload image. Image needs to be .PNG or .SVG only. Upload a .PNG or .SVG file.";
const IMAGE_TOO_SMALL =
  "Can't upload image. Image dimension is too small. Upload an image that is 384x384 pixels or larger.";
const IMAGE_NOT_SQUARE =
  "Can't upload image. App logo needs to be a perfect square. Upload a square image.";

export const ValidationErrors = {
  FILE_SIZE_TOO_LARGE,
  INCORRECT_FILE_TYPE,
  IMAGE_TOO_SMALL,
  IMAGE_NOT_SQUARE,
};
export const ValidationHelpers = { ValidateImage, ValidateFile, LoadImage };

function LoadImage(imageFile: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(imageFile);
  });
}

function ValidateFile({ file, displayError }: ErrorProps) {
  let validFile = false;
  if (
    file.type.toLocaleLowerCase().includes("svg") ||
    file.type.toLocaleLowerCase().includes("png")
  ) {
    if (file.size <= 1048576) {
      validFile = true;
    } else {
      displayError(FILE_SIZE_TOO_LARGE);
    }
  } else {
    displayError(INCORRECT_FILE_TYPE);
  }

  return validFile;
}

async function ValidateImage({ file, displayError }: ErrorProps) {
  let validImage = false;

  if (ValidationHelpers.ValidateFile({ file, displayError })) {
    const image = await ValidationHelpers.LoadImage(file);

    if (image.width === image.height) {
      if (file.type.includes("svg")) {
        validImage = true;
      } else if (image.width >= 384 && image.height >= 384) {
        validImage = true;
        displayError(undefined);
      } else {
        displayError(IMAGE_TOO_SMALL);
      }
    } else {
      displayError(IMAGE_NOT_SQUARE);
    }
  }

  return validImage;
}
