import React from "react";
import { PageProps } from "gatsby";
import { Page } from "@/components/Page";
import { ManageApps } from "@/views/ManageApps";
import { ApplicationForm } from "@/features/Application/ApplicationForm";
import { MFABanner } from "@/features/Authentication/MFABanner";
import { withAuthentication } from "@/features/Authentication/AccessRestriction";

interface AppsPageLocationState {
  firstLogIn?: boolean;
}

function AppsPage({
  params: { "*": id },
  location: { state },
}: PageProps<unknown, unknown, AppsPageLocationState>) {
  const firstLogIn = state?.firstLogIn || false;
  return (
    <Page>
      {id ? (
        <ApplicationForm applicationId={id == "new" ? undefined : id} />
      ) : (
        <>
          {firstLogIn && <MFABanner />}
          <ManageApps />
        </>
      )}
    </Page>
  );
}

export default withAuthentication(AppsPage);
