import { gql } from "@apollo/client";

const OAUTH_APPLICATION_FIELDS = gql`
  fragment OauthApplicationFields on Application {
    oauthApplication {
      id
      name
      description
      by
      redirectUrls
      secret
      refreshTokenRotation
    }
  }
`;

export const GET_APPLICATION = gql`
  ${OAUTH_APPLICATION_FIELDS}
  query GetApplication($id: EncodedId!) {
    application(id: $id) {
      id
      state
      applicationScopes
      applicationOptionalScopes
      reauthorizeMessage
      manageAppUrl
      features {
        label
      }
      galleryImages {
        id
        url
        filename
        fileSize
        contentType
      }
      logo {
        id
        url
        filename
        fileSize
        contentType
      }
      appWebHooks {
        nodes {
          id
          topic
          url
        }
      }
      ...OauthApplicationFields
      originalApp {
        applicationScopes
        applicationOptionalScopes
      }
      termsOfServiceUrl
      privacyPolicyUrl
    }
  }
`;

export const UPSERT_APPLICATION = gql`
  ${OAUTH_APPLICATION_FIELDS}
  mutation UpsertApplication(
    $attributes: ApplicationUpdateAttributes!
    $id: EncodedId
  ) {
    upsertApplication(attributes: $attributes, id: $id) {
      application {
        id
        state
        applicationScopes
        applicationOptionalScopes
        reauthorizeMessage
        manageAppUrl
        features {
          label
        }
        galleryImages {
          id
          url
          filename
          fileSize
          contentType
        }
        logo {
          id
          url
          filename
          fileSize
          contentType
        }
        appWebHooks {
          nodes {
            id
            topic
            url
          }
        }
        termsOfServiceUrl
        privacyPolicyUrl
        ...OauthApplicationFields
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const UPSERT_WEBHOOK = gql`
  mutation UpsertWebhook(
    $attributes: AppWebHookUpdateAttributes!
    $id: EncodedId
  ) {
    appWebHookUpsert(id: $id, attributes: $attributes) {
      appWebHook {
        id
        topic
        url
      }
      userErrors {
        message
        path
      }
    }
  }
`;

export const DESTROY_WEBHOOK = gql`
  mutation Destroy_Webhook($ids: [EncodedId!]!) {
    appWebHookDestroy(ids: $ids) {
      userErrors {
        message
        path
      }
    }
  }
`;

export const DIRECT_UPLOAD_CREATE = gql`
  mutation DirectUploadCreate($input: DirectUploadCreateInput!) {
    directUploadCreate(input: $input) {
      userErrors {
        path
        message
      }
      directUpload {
        parameters {
          value
          name
        }
        url
        id
      }
    }
  }
`;

export const DIRECT_UPLOAD_COMPLETE = gql`
  mutation DirectUploadComplete($id: EncodedId!) {
    directUploadComplete(directUploadId: $id) {
      userErrors {
        path
        message
      }
      directUpload {
        parameters {
          value
          name
        }
        url
        id
      }
    }
  }
`;
