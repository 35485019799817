import React from "react";
import { Text } from "@jobber/components/Text";
import { Button } from "@jobber/components/Button";
import { Content } from "@jobber/components/Content";
import { FeatureListItem, FeatureListItemProps } from "./FeatureListItem";

export type Feature = Pick<FeatureListItemProps, "label">;

interface FeatureListProps {
  features: Feature[];
  readonly: boolean;
  onChange(features: Feature[]): void;
}

export function FeatureList({
  features,
  readonly,
  onChange,
}: FeatureListProps) {
  const handleFeatureItemChange = (index: number, newFeature: Feature) =>
    onChange(
      features.map((feature, featureIndex) =>
        featureIndex === index ? newFeature : feature,
      ),
    );

  const handleFeatureItemDelete = (matchIndex: number) =>
    onChange(
      features.filter((_value, featureIndex) => featureIndex !== matchIndex),
    );

  const handleFeatureItemAdd = () => onChange([...features, { label: "" }]);

  return (
    <Content spacing="small">
      <Content spacing="small">
        {features.map((feature, index) => {
          const handleFeatureItemLabelChange = (newLabel: string) =>
            handleFeatureItemChange(index, { ...feature, label: newLabel });

          const handleDelete = () => handleFeatureItemDelete(index);
          return (
            <FeatureListItem
              key={index}
              readonly={readonly}
              label={feature.label}
              canDelete={index > 0}
              onLabelChange={handleFeatureItemLabelChange}
              onDelete={handleDelete}
            />
          );
        })}
      </Content>
      {features.length < 5 && !readonly && (
        <Button
          label="Add Another"
          variation="work"
          type="secondary"
          size="small"
          onClick={handleFeatureItemAdd}
        />
      )}
      <Text variation="subdued">
        Max 5 features or benefits and 125 characters each
      </Text>
    </Content>
  );
}
