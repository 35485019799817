/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import {
  ApolloError,
  FetchResult,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { showToast } from "@jobber/components/Toast";
import { FileUpload } from "@jobber/components/InputFile";
import {
  ApplicationUpdateAttributes,
  DestroyWebhookMutation,
  DestroyWebhookMutationVariables,
  DirectUploadCompleteMutation,
  DirectUploadCompleteMutationVariables,
  DirectUploadCreateInput,
  DirectUploadCreateMutation,
  DirectUploadCreateMutationVariables,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  Maybe,
  MutationErrors,
  Scalars,
  UpsertApplicationMutation,
  UpsertApplicationMutationVariables,
  UpsertApplicationPayload,
  UpsertWebhookMutation,
  UpsertWebhookMutationVariables,
} from "@/utils/graphql/types";
import { useDisplayError } from "@/hooks/useDisplayError";
import { useBannerContext } from "@/context/banner";
import {
  DESTROY_WEBHOOK,
  DIRECT_UPLOAD_COMPLETE,
  DIRECT_UPLOAD_CREATE,
  GET_APPLICATION,
  UPSERT_APPLICATION,
  UPSERT_WEBHOOK,
} from "./ApplicationForm.graphql";
import { ApplicationForm, GalleryFile } from "./ApplicationForm";
import { WebHook } from "./WebHookList";
import { useApplicationFormReducer } from "./ApplicationFormReducer";
import { BAD_IMAGE } from "./ImageGallery";
import { BAD_LOGO_IMAGE } from "./LogoUpload";
import { ScopeCollectionManipulators } from "./ScopesForm";

interface ApplicationFormLoaderProps {
  applicationId?: string;
}
interface UpdatedApplicationUpdateAttributes
  extends Omit<ApplicationUpdateAttributes, "logoId"> {
  logoId: Maybe<Scalars["EncodedId"]> | null;
}
interface UpdatedUpsertApplicationMutationVariables
  extends Omit<UpsertApplicationMutationVariables, "attributes"> {
  attributes: UpdatedApplicationUpdateAttributes;
}

export function ApplicationFormLoader({
  applicationId,
}: ApplicationFormLoaderProps) {
  const { displayError } = useDisplayError();
  const { hideBanner } = useBannerContext();
  const { state, dispatch } = useApplicationFormReducer();
  const [originalScopes, setOriginalScopes] = useState(state.scopes);

  const [files, setFiles] = useState<GalleryFile[]>([]);

  const [logoFile, setLogoFile] = useState<FileUpload>();
  const { buildScopeCollectionObject, convertToString } =
    ScopeCollectionManipulators();

  async function handleUpload(file: FileUpload) {
    const newUrl = await file.src();
    setFiles(oldFiles => updateFiles({ ...file, src: newUrl }, oldFiles));
  }

  function handleLogoUpload(file: FileUpload) {
    setLogoFile(file);
  }

  function handleLogoDelete() {
    dispatch({
      type: "Remove logo",
    });
    setLogoFile(undefined);
  }

  useEffect(() => {
    const needsUpdating = files.find(element => {
      return element.key === BAD_IMAGE;
    });
    if (needsUpdating) {
      setFiles(oldFiles => {
        return oldFiles.reduce((prev, curr) => {
          if (curr.key !== BAD_IMAGE) {
            prev.push(curr);
          }
          return prev;
        }, [] as GalleryFile[]);
      });
    }
  }, [files]);

  useEffect(() => {
    if (logoFile?.key === BAD_LOGO_IMAGE) {
      setLogoFile(undefined);
    }
  }, [logoFile]);

  useEffect(() => {
    if (!logoFile?.key && state.logo?.id) {
      const logo = state.logo;
      setLogoFile({
        key: logo.id,
        name: logo.filename,
        type: logo.contentType,
        size: logo.fileSize,
        progress: 1,
        src: () => Promise.resolve(logo.url),
      } as FileUpload);
    }
  });

  function handleDelete(file: GalleryFile) {
    setFiles(
      files.filter(element => {
        return element.key !== file.key;
      }),
    );
    dispatch({ type: "Remove image", value: file.key });
  }

  const updateStateFromGraphQLType = (
    data: GetApplicationQuery | UpsertApplicationPayload,
  ) => {
    if (!data.application) return;
    const {
      id,
      state: status,
      applicationScopes: scopes,
      applicationOptionalScopes: optionalScopes,
      originalApp,
      reauthorizeMessage,
      manageAppUrl: manageAppUrl,
      appWebHooks: { nodes: appWebHooks },
      oauthApplication: {
        id: clientId,
        secret,
        name: title,
        description,
        by: author,
        redirectUrls: callback,
        refreshTokenRotation,
      },
      galleryImages,
      logo,
      termsOfServiceUrl,
      privacyPolicyUrl,
    } = data.application;

    setOriginalScopes(
      originalApp
        ? buildScopeCollectionObject(
            originalApp.applicationScopes,
            originalApp.applicationOptionalScopes,
          )
        : buildScopeCollectionObject(scopes, optionalScopes),
    );

    setFiles([]);
    const features = data.application.features
      ? data.application.features.map((feature: { label: string }) => ({
          label: feature.label,
        }))
      : [{ label: "" }];
    dispatch({
      type: "Set State",
      value: {
        id,
        status,
        scopes: buildScopeCollectionObject(scopes, optionalScopes),
        reauthorizeMessage,
        webHooks: appWebHooks,
        removedWebHooks: [],
        clientId,
        secret,
        title,
        description,
        author,
        features,
        callback,
        manageAppUrl,
        galleryImages,
        imagesToAdd: [],
        imagesToRemove: [],
        refreshTokenRotation,
        logo: logo ? logo : undefined,
        logoId: logo ? logo.id : undefined,
        termsOfServiceUrl: termsOfServiceUrl,
        privacyPolicyUrl: privacyPolicyUrl,
      },
    });
  };

  const handleCompletedQuery = (data: GetApplicationQuery) => {
    if (data.application) updateStateFromGraphQLType(data);
  };

  const handleGraphQLError = (error: ApolloError) => {
    displayError([error.message]);
  };

  const [fetchApplication, { loading: isLoadingData }] = useLazyQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GET_APPLICATION, {
    fetchPolicy: "cache-and-network",
    onCompleted: handleCompletedQuery,
    onError: handleGraphQLError,
  });

  const handleCompletedApplicationUpsert = (
    data: UpsertApplicationMutation,
  ) => {
    const { userErrors } = data.upsertApplication;
    if (userErrors.length > 0) {
      displayError(userErrors.map(error => error.message));
      return;
    }

    if (data.upsertApplication) {
      showToast({
        message: "App updated",
        variation: "success",
      });
      hideBanner();
      updateStateFromGraphQLType(
        data.upsertApplication as UpsertApplicationPayload,
      );
    }
  };

  const [upsertApplication, { loading: isLoadingSave }] = useMutation<
    UpsertApplicationMutation,
    UpdatedUpsertApplicationMutationVariables
  >(UPSERT_APPLICATION, {
    onCompleted: handleCompletedApplicationUpsert,
    onError: handleGraphQLError,
  });

  const [directUploadCreate] = useMutation<
    DirectUploadCreateMutation,
    DirectUploadCreateMutationVariables
  >(DIRECT_UPLOAD_CREATE);

  async function createDirectUpload(payload: DirectUploadCreateInput) {
    try {
      const result = await directUploadCreate({
        variables: { input: payload },
      });

      const userErrors = result.data?.directUploadCreate
        .userErrors as MutationErrors[];

      if (userErrors.length > 0) {
        displayError(userErrors.map(error => error.message));
        return undefined;
      } else {
        return result.data || undefined;
      }
    } catch (error) {
      displayError(["Something went wrong, please try again"]);
      return;
    }
  }

  const [directUploadComplete] = useMutation<
    DirectUploadCompleteMutation,
    DirectUploadCompleteMutationVariables
  >(DIRECT_UPLOAD_COMPLETE);

  async function completeDirectUpload(key: string) {
    try {
      const result = await directUploadComplete({
        variables: { id: key },
      });

      const userErrors = result.data?.directUploadComplete
        .userErrors as MutationErrors[];

      if (userErrors.length > 0) {
        handleLogoDelete();
        displayError(userErrors.map(error => error.message));
        return undefined;
      } else {
        return result.data || undefined;
      }
    } catch (error) {
      displayError(["Something went wrong, please try again"]);
      return;
    }
  }

  const handleCompletedAppWebHookUpsert = (data: UpsertWebhookMutation) => {
    const { userErrors } = data.appWebHookUpsert;
    if (userErrors.length > 0) {
      displayError(userErrors.map(error => error.message));
    }
  };

  const [upsertAppWebHook] = useMutation<
    UpsertWebhookMutation,
    UpsertWebhookMutationVariables
  >(UPSERT_WEBHOOK, {
    onCompleted: handleCompletedAppWebHookUpsert,
    onError: handleGraphQLError,
  });

  const handleCompletedAppWebHookDestroy = (data: DestroyWebhookMutation) => {
    const { userErrors } = data.appWebHookDestroy;
    if (userErrors.length > 0) {
      displayError(userErrors.map(error => error.message));
    }
  };

  const [destroyAppWebHook] = useMutation<
    DestroyWebhookMutation,
    DestroyWebhookMutationVariables
  >(DESTROY_WEBHOOK, {
    onCompleted: handleCompletedAppWebHookDestroy,
    onError: handleGraphQLError,
  });

  useEffect(() => {
    if (applicationId) fetchApplication({ variables: { id: applicationId } });
  }, [applicationId]);

  const upsertWebHooks = (id: string) => {
    const upsertedWebHookPromises: Promise<
      FetchResult<UpsertWebhookMutation>
    >[] = [];
    const { webHooks } = state;
    if (id) {
      webHooks.forEach(hook => {
        const { id: hookId, topic, url } = hook;
        if (topic != "") {
          upsertedWebHookPromises.push(
            upsertAppWebHook({
              variables: {
                id: hookId,
                attributes: { appId: id, topic: topic, url: url },
              },
            }),
          );
        }
      });
    }
    return Promise.all(upsertedWebHookPromises);
  };

  const handleSave = async () => {
    if (!state.isDirty) {
      return displayError(["No changes to save"]);
    }

    const {
      title: name,
      description,
      scopes,
      reauthorizeMessage,
      author: by,
      callback: redirectUrls,
      manageAppUrl,
      features,
      removedWebHooks,
      webHooks,
      imagesToAdd,
      imagesToRemove,
      refreshTokenRotation,
      logoId,
      termsOfServiceUrl,
      privacyPolicyUrl,
    } = state;

    const scopesStrings = convertToString(scopes);

    const hasHookWithoutTopic = webHooks.some(hook => hook.topic == "");

    if (hasHookWithoutTopic) {
      displayError([
        "A webhook does not have a topic selected. Add one or remove the webhook to save.",
      ]);
      return;
    }

    if (scopesStrings.scopes.length == 0) {
      displayError(["Choose at least one scope for your app. "]);
      return;
    }

    await destroyAppWebHook({
      variables: {
        ids: removedWebHooks
          .filter(hook => hook.id)
          .map(hook => hook.id as string),
      },
    });

    const { data } = await upsertApplication({
      variables: {
        attributes: {
          name,
          description,
          by,
          applicationScopes: scopesStrings.scopes,
          applicationOptionalScopes: scopesStrings.optionalScopes,
          reauthorizeMessage: reauthorizeMessage,
          redirectUrls,
          manageAppUrl,
          features,
          imagesToAdd,
          imagesToRemove,
          refreshTokenRotation,
          logoId: logoId ? logoId : null,
          termsOfServiceUrl,
          privacyPolicyUrl,
        },
        id: applicationId || state.id,
      },
    });
    if (data?.upsertApplication.application?.id != undefined) {
      const newApplicationId = data.upsertApplication.application.id;
      const upsertResults = await upsertWebHooks(newApplicationId);
      dispatch({
        type: "Change Web Hooks",
        value: upsertResults
          .map(upsertResult => {
            const webhook = upsertResult.data?.appWebHookUpsert.appWebHook;
            if (webhook) {
              const { id: webhookId, topic, url } = webhook;
              return { id: webhookId, topic, url };
            }
          })
          .filter(webhook => webhook?.id) as WebHook[],
      });
    }
  };

  const allImages = files.concat(
    state.galleryImages.map(image => {
      return {
        key: image.id,
        name: image.filename,
        type: image.contentType,
        src: image.url,
        size: image.fileSize,
        progress: 1,
      } as GalleryFile;
    }),
  );

  return (
    <>
      <ApplicationForm
        id={applicationId || state.id}
        {...state}
        originalScopes={originalScopes}
        onSave={handleSave}
        dispatch={dispatch}
        createDirectUpload={createDirectUpload}
        handleDelete={handleDelete}
        isLoadingData={isLoadingData}
        isLoadingSave={isLoadingSave}
        files={allImages}
        logoFile={logoFile ? logoFile : undefined}
        handleUpload={handleUpload}
        handleLogoUpload={handleLogoUpload}
        handleLogoDelete={handleLogoDelete}
        completeDirectUpload={completeDirectUpload}
      />
    </>
  );
}

function updateFiles(updatedFile: GalleryFile, files: GalleryFile[]) {
  const newFiles = [...files];
  const index = files.findIndex(file => file.key === updatedFile.key);

  if (index !== -1) {
    newFiles[index] = updatedFile;
  } else {
    newFiles.push(updatedFile);
  }

  return newFiles;
}
