import React from "react";
import { Autocomplete, Option } from "@jobber/components/Autocomplete";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { InputText } from "@jobber/components/InputText";
import { WebHookTopicEnum } from "@/utils/graphql/types";
import * as styles from "./WebHookListItem.module.css";

export interface WebHook {
  id?: string;
  topic: WebHookTopicEnum | "";
  url: string;
}

interface WebHookListItemProps extends WebHook {
  readonly: boolean;
  availableOptions: Option[];
  onTopicChange(topic: WebHookTopicEnum): void;
  onUrlChange(url: string): void;
  onDelete(): void;
}

export function WebHookListItem({
  topic,
  url,
  readonly,
  availableOptions,
  onTopicChange,
  onUrlChange,
  onDelete,
}: WebHookListItemProps) {
  const currentlySelectedOption: Option = { label: topic, value: topic };

  // ignored due to difficulty in testing
  const getFilteredTopics = /* istanbul ignore next */ (
    searchText: string,
  ): Option[] => {
    if (searchText === "") return availableOptions;
    return availableOptions.filter(option =>
      option.label.match(new RegExp(searchText, "i")),
    );
  };

  return (
    <Content>
      <div className={styles.line_item}>
        <div style={{ flex: "1" }}>
          <Content spacing="small">
            {readonly ? (
              <InputText
                placeholder="Select a topic"
                readonly={readonly}
                size="small"
                value={topic}
              />
            ) : (
              <Autocomplete
                placeholder="Select a topic"
                size="small"
                initialOptions={availableOptions}
                onChange={option => {
                  if (option?.value) {
                    onTopicChange(option.value as WebHookTopicEnum);
                  }
                }}
                value={currentlySelectedOption}
                getOptions={getFilteredTopics}
              />
            )}
            <InputText
              readonly={readonly}
              validations={{
                required: {
                  value: true,
                  message: "A URL is required to enable a web hook",
                },
                validate: (value: string) => {
                  if (value.match(new RegExp(/https:\/\/.+/)) == null) {
                    return "You must use 'https://'";
                  }
                },
              }}
              placeholder="Webhook URL"
              size="small"
              value={url}
              onChange={onUrlChange}
            />
          </Content>
        </div>
        {!readonly && (
          <div>
            <Button
              icon="trash"
              type="tertiary"
              variation="destructive"
              ariaLabel="Delete webhook"
              onClick={onDelete}
            />
          </div>
        )}
      </div>
    </Content>
  );
}
