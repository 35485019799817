import { cloneDeep, snakeCase } from "lodash";
import { ScopeCollection, initialState } from "./initialState";
import { applicableScopes } from "./applicableScopes";
import { ScopeItemType } from "./ScopeItem";

export const ScopeCollectionManipulators = () => {
  /**
   * Will try to safely add on scopes, if it exists already, it won't be added a second time.
   * @param existingScopes The array of scopes to try to add-on to
   * @param scopesToAdd The array of scopes to try and add
   * @returns The updated scopes with elements added
   */
  const safelyAddScopes = (
    existingScopes: string[],
    scopesToAdd: string[],
  ): string[] => Array.from(new Set([...existingScopes, ...scopesToAdd]));

  /**
   * Will format any specified scope to ensure that there are no capitals or spaces
   * @param scope The scope to format
   * @returns The formatted scope (no spaces or capitals)
   */
  const getFormattedScope = (scope: string) =>
    snakeCase(scope).toLowerCase().replace(" ", "_");

  /**
   * Will retrieve the enabled or disabled permission types for a given scope item
   * @param scopeItem The scope item
   * @param isEnabled Option to return enabled or disabled permission types
   * @returns enabled/disabled permission types
   */
  const getScopePermissions = (
    scopeItem: ScopeItemType,
    isEnabled: boolean,
  ): string[] =>
    Object.entries(scopeItem.permissionTypes)
      .filter(
        ([, value]) =>
          (isEnabled == true && value == true) ||
          (isEnabled == false && value == false),
      )
      .map(([key]) => getFormattedScope(`${key}_${scopeItem.key}`));

  const getRequiredScopes = (scopeItem: ScopeItemType): string[] =>
    scopeItem.requiredScopes;

  /**
   * Will return a list of the scopes which are dependent on the scopes which are being removed
   * @param scopesCollection Current state of all scopes
   * @param scopesBeingRemoved The scopes which are about to be removed
   * @returns The scopes which are dependent on the scopes being removed
   */
  const getDependentScopes = (
    scopesCollection: ScopeCollection,
    scopesBeingRemoved: string[],
  ): string[] => {
    const dependentScopes: string[] = [];
    Object.entries<ScopeItemType>(scopesCollection)
      .filter(([, scopeItemValue]) =>
        scopesBeingRemoved.reduce(
          (prev: boolean, curr) =>
            prev || scopeItemValue.requiredScopes.includes(curr),
          false,
        ),
      )
      .forEach(([key]) => {
        dependentScopes.push(getFormattedScope(`read_${key}`));
        dependentScopes.push(getFormattedScope(`write_${key}`));
      });
    return dependentScopes;
  };

  /**
   * Will handle the conversion of internal component state to the desired string state
   * @param scopeCollection Current state of all scopes
   * @param scopeItem The updated/changed scope item
   */
  const handleChange = (
    scopeCollection: Record<string, ScopeItemType>,
    scopeItem: ScopeItemType,
  ) => {
    scopeCollection[scopeItem.key] = scopeItem;

    const requiredGrantedScopes = getRequiredScopes(scopeItem);

    requiredGrantedScopes.forEach(scope => {
      if (applicableScopes.has(scope)) {
        updateScope(scope, scopeCollection, false, true);
      }
    });

    const turnedOffScopes = getScopePermissions(scopeItem, false);
    const dependentScopes = getDependentScopes(
      scopeCollection,
      turnedOffScopes,
    );

    dependentScopes.forEach(scope => {
      if (applicableScopes.has(scope)) {
        updateScope(scope, scopeCollection, false, false);
      }
    });
  };

  /**
   * Will handle the conversion of internal component state to the desired string
   * @param scopesCollection Current Scopes State
   * @returns An object of scopes and optional scopes in desired string
   */
  const convertToString = (
    scopesCollection: ScopeCollection,
  ): { scopes: string; optionalScopes: string } => {
    let scopes: string[] = [];
    let optionalScopes: string[] = [];

    Object.values(scopesCollection).forEach(scope => {
      if (scope.value) {
        const turnedOnPermissions = getScopePermissions(scope, true);

        if (scope.optional) {
          optionalScopes = safelyAddScopes(optionalScopes, turnedOnPermissions);
        } else {
          scopes = safelyAddScopes(scopes, turnedOnPermissions);
        }
      }
    });

    return {
      scopes: scopes.join(" ").trim(),
      optionalScopes: optionalScopes.join(" ").trim(),
    };
  };

  /**
   * Will handle the conversion of string to internal component object
   * @param  scopes String separated by space list of scopes
   * @param  optionalScopes String separated by space list of optional scopes
   * @returns An object of scope items
   */
  function buildScopeCollectionObject(
    scopes: string,
    optionalScopes: string | undefined,
  ): ScopeCollection {
    // deep clone is required here so that we don't point to the same
    // initialState object we want to use for setting
    const scopeCollection = cloneDeep(initialState);

    // splitting on empty string still returns an array with an empty string so we have to filter
    const scopeList = scopes?.split(" ").filter(key => key !== "");
    const optionalScopeList = optionalScopes
      ?.split(" ")
      .filter(key => key !== "");

    scopeList?.forEach(scope => {
      if (applicableScopes.has(scope)) {
        updateScope(scope, scopeCollection, false, true);
      }
    });

    optionalScopeList?.forEach(optionalScope => {
      if (applicableScopes.has(optionalScope)) {
        updateScope(optionalScope, scopeCollection, true, true);
      }
    });

    return scopeCollection;
  }

  return { handleChange, convertToString, buildScopeCollectionObject };
};

function updateScope(
  scope: string,
  scopeCollection: ScopeCollection,
  optional: boolean,
  value: boolean,
) {
  const [key, permissionTypeKey] = applicableScopes.get(scope) as string[];

  scopeCollection[key].permissionTypes[permissionTypeKey] = value;
  scopeCollection[key].value = determineScopeValue(scopeCollection[key]);
  scopeCollection[key].optional = optional;
}

function determineScopeValue(scope: ScopeItemType): boolean {
  let scopesValue = false;

  Object.entries(scope.permissionTypes).forEach(([, permissionTypeValue]) => {
    if (permissionTypeValue) {
      scopesValue = true;
    }
  });

  return scopesValue;
}
