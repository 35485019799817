import { showToast } from "@jobber/components/Toast";
import copy from "clipboard-copy";
import { useDisplayError } from "@/hooks/useDisplayError";

export function useClipboardActions() {
  const { displayError } = useDisplayError();
  const copyText = async (text: string) => {
    try {
      await copy(text);
      showToast({
        message: "Copied to clipboard",
      });
    } catch (error) {
      displayError([error]);
    }
  };
  return { copyText };
}
